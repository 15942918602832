import createInitialState from '../initialState';
import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from '../../actions/actionTypes';

const notificationReducer = (state = createInitialState().notification, action) => {
    const newState = { ...state };
    switch (action.type) {
        case SHOW_NOTIFICATION: {
            newState.message = action.payload.message;
            newState.variant = action.payload.variant;
            break;
        }
        case HIDE_NOTIFICATION: {
            newState.message = action.payload.message;
            break;
        }
        default:
            break;
    }
    return newState;
};

export default notificationReducer;
