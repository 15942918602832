import _ from 'lodash';

const UserUtils = {
    _domain: {
        currency: '',
        pretty_proposal_custom_color: '',
        external_saturation_url: '',
        audience_url: '',
        price_format: '#,###,###.00', // 2 decimals points will always be shown (as .00 if not entered)
        price_format_optional_decimals: '#,###,###.##', // 2 decimals points will be shown only if entered
        impressions_format: '#,###,###',
        cpm_base_format: '#,###,###.##',
        cpm_extended_format: '#,###,###.####',
        goal_tolerance_percentage: 0.1,
        goal_tolerance_percent_format: '0.0%',
        goal_value_display_format: '#,###,###.##',
        static_lead_time: 2,
        static_flight_duration: {
            flight_type: 0,
            flight_duration: 14,
        },
        empty_inventory: {
            static: false,
            digital: false,
        },
        cpm_override: false,
        priority_levels: [],
        timezone: 'America/Montreal',
        date_format: 'DD-MM-YYYY',
        enhanced_hold_renewal_enabled: false,
        proposal_goal_enabled: false,
    },

    setDomainReadOnly(domainData) {
        this._domain = _.extend(this._domain, domainData);
    },

    get(key) {
        // eslint-disable-next-line
        if (key && Object.prototype.hasOwnProperty.call(this._domain, key)) {
            // eslint-disable-next-line
            return this._domain[key];
        }
        return null;
    },

    hasSalesforceIntegration() {
        const salesforce = this.get('salesforce');
        return typeof salesforce === 'object' && salesforce !== null;
    },
};

export default UserUtils;
