import importedTranslations from './i18n.en';

export default {
    ...importedTranslations,
    LblProposalOptions: 'Custom campaign options',
    LblProposalHoldDuration: 'Campaign hold automatic expiry time',
    LblProposalHeader: 'Campaign header',
    LblProposalThemeColor: 'Campaign accent color',
    ErrorDeleteUsedPriority:
        'This priority level links to a campaign, so you are unable to remove it.',
    LblLetterheadExplanation: 'The chosen image will appear at the top of every generated campaign',
};
