const Config = {
    defaults: {
        pretty_proposal_color: '#802EF8',
        sov: 16.67,
        takeover: 100,
        poi_radius: 30,
        poi_min_radius: 0,
        poi_max_radius: 999999,
        static_theme: {
            bookedColor: '#039188',
            heldColor: '#03b592',
            proposedColor: '#04ddb5',
        },
        digital_theme: {
            bookedColor: '#0389ce',
            heldColor: '#00a8ff',
            proposedColor: '#86d4fc',
        },
    },

    urls: {
        imageUploadUrls: {
            screen: {
                upload: '/api/v1/screen/{resource_id}/image',
                remove: '/api/v1/screen/{resource_id}/image',
            },
            proposal: {
                upload: '/api/upload/heropic/v1/',
                remove: '/api/remove/heropic/v1/',
            },
            letterhead: {
                upload: '/api/v1/domain/letterhead',
                remove: '/api/v1/domain/letterhead',
            },
        },
        cart: '/api/v1/cart',
        proposal: '/api/v1/proposal',
        proposal_item: {
            put: '/api/v1/proposal/proposal_item/',
        },
        summary: {
            post: '/api/v1/cart',
        },
        screenIdSelect: {
            post: '/api/v1/screen/selection',
        },
        orientationSearch: {
            post: '/api/v1/orientation/search',
        },
        resolutionSearch: {
            post: '/api/v1/resolution/search',
        },
        categoryExclusion: {
            csv: '/api/v1/category_exclusion/csv',
        },
        screenSearch: {
            post: '/api/v1/screen/search',
            csv: '/api/v1/screen/search/csv',
        },
        formatSearch: {
            post: '/api/v1/screen_format/search',
        },
        screen: {
            post: '/api/v1/screen',
            csv: '/api/v1/screen/csv',
        },
        locationSearch: {
            post: '/api/v1/location/search',
        },
        poiSearch: {
            post: '/api/v1/point_of_interest/search',
            get: '/api/v1/point_of_interest/search',
        },
        criteriaSearch: {
            post: '/api/v1/criterion/search',
        },
        poiCSV: {
            csv: '/api/v1/point_of_interest/csv',
            custom: '/api/v1/point_of_interest/csv/custom',
        },
        customScreen: {
            list: '/api/v1/screen/custom/list',
            csv: '/api/v1/screen/custom/csv',
            validate: '/api/v1/screen/curation',
        },
        proposalCSV: {
            csv: '/api/v1/proposal/csv',
        },
        networkBookingLevel: {
            post: '/api/v1/network/booking',
        },
        rateCardSearch: {
            post: '/api/v1/rate_card/search',
        },
        rateCard: {
            post: '/api/v1/rate_card',
        },
        importAudience: {
            save: '/api/v1/audience/csv',
        },
        domain: {
            post: '/api/v1/domain',
        },
        dynamicPricing: {
            post: '/api/v1/rate_card/multiplier',
            search: {
                post: '/api/v1/rate_card/multiplier/search',
            },
            date: {
                post: '/api/v1/rate_card/multiplier/',
            },
        },
        user: {
            post: '/api/v1/user',
            read: '/api/v1/user/search',
            put: '/api/v1/user',
            filter: 'api/v1/user/filter',
            export: 'api/v1/user/csv',
        },
        static: {
            save: '/api/v1/static/csv',
        },
        closeOfPlan: {
            preview: '/api/v1/static/plan/csv',
            close: '/api/v1/static/plan/csv',
            save: '/api/v1/static/plan/csv',
        },
        domainIntegration: {
            salesforce: {
                post: '/api/v1/domain/integration/salesforce',
            },
        },
    },
    mapStyles: [
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#53a4f9',
                    // 'color': '#009beb' this one is cute too
                },
                {
                    lightness: 17,
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#f5f5f5',
                },
                {
                    lightness: 20,
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#ffffff',
                },
                {
                    lightness: 17,
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#ffffff',
                },
                {
                    lightness: 29,
                },
                {
                    weight: 0.2,
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#ffffff',
                },
                {
                    lightness: 18,
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#ffffff',
                },
                {
                    lightness: 16,
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#f5f5f5',
                },
                {
                    lightness: 21,
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#dedede',
                },
                {
                    lightness: 21,
                },
            ],
        },
        {
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    visibility: 'on',
                },
                {
                    color: '#ffffff',
                },
                {
                    lightness: 16,
                },
            ],
        },
        {
            elementType: 'labels.text.fill',
            stylers: [
                {
                    saturation: 36,
                },
                {
                    // 'color': '#333333'
                    color: '#333333',
                },
                {
                    lightness: 40,
                },
            ],
        },
        {
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#f2f2f2',
                },
                {
                    lightness: 19,
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#fefefe',
                },
                {
                    lightness: 20,
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#fefefe',
                },
                {
                    lightness: 17,
                },
                {
                    weight: 1.2,
                },
            ],
        },
    ],
};

export default Config;
