import { datadogRum } from '@datadog/browser-rum';
import _ from 'lodash';

import store from '../store/store';

const { gtag } = window;
const defaultMethod = 'Google';

const getCurrentUser = () => {
    const reduxStoreState = store.getState();
    const { STApp } = window;

    let userData = null;

    if (Object.keys(reduxStoreState.global.user).length > 0) {
        const { domain_id: domainId, role_id: roleId, id: userId } = reduxStoreState.global.user;
        const { name: domainName } = reduxStoreState.global.domain;
        userData = {
            domainId,
            domainName,
            roleId,
            userId,
        };
    } else if (!_.isNil(STApp) && STApp._userModel) {
        userData = {
            domainId: STApp._userModel.get('domain_id'),
            domainName: STApp._userModel.get('domain').name,
            roleId: STApp._userModel.get('role_id'),
            userId: STApp._userModel.get('id'),
        };
    }
    return userData;
};

const getExtraUserData = currentUser => {
    return {
        domainId: currentUser.domainId,
        domainName: currentUser.domainName,
        roleId: currentUser.roleId,
        userId: currentUser.userId,
    };
};

/**
 * Will attempt to send event to Google and log error to Datadog RUM if failed
 * @param type - type of the event (required by GA events)
 * @param action - title of the event (required by GA events)
 * @param eventObj - event object with additional information
 */
const sendReportToGoogle = (type, action, eventObj) => {
    try {
        let extraFields = {};
        const currentUser = getCurrentUser();
        if (currentUser && eventObj.extraData) {
            extraFields = {
                User_Id: eventObj.extraData.userId,
                Domain_Id: eventObj.extraData.domainId,
                Domain_Name: eventObj.extraData.domainName,
                Role_Id: eventObj.extraData.roleId,
            };
        }
        gtag(type, action, {
            event_category: eventObj.event_category,
            event_label: eventObj.event_label,
            value: 1,
            ...extraFields,
        });
    } catch (error) {
        datadogRum.addError(error);
    }
};

const EventUtils = {
    loadPage: (currentPage, options = {}) => {
        let extraData = null;
        const currentUser = getCurrentUser();

        if (currentUser) {
            gtag('set', 'userId', currentUser.userId);
            extraData = getExtraUserData(currentUser);
        }
        const event = {
            event_category: 'navigation',
            event_label: `Load page ${currentPage}`,
            method: options.method || defaultMethod,
            extraData,
        };
        sendReportToGoogle('event', 'loadPage', event);
    },

    switchPage: (previousPage, nextPage, options = {}) => {
        const currentUser = getCurrentUser();
        const extraData = currentUser ? getExtraUserData(currentUser) : null;
        const event = {
            event_category: 'navigation',
            event_label: `Move from ${previousPage} to ${nextPage}`,
            method: options.method || defaultMethod,
            extraData,
        };

        sendReportToGoogle('event', 'switchPage', event);
    },
    action: (label, category, action = 'click', options = {}) => {
        const currentUser = getCurrentUser();
        const extraData = currentUser ? getExtraUserData(currentUser) : null;
        const event = {
            event_category: category,
            event_label: label,
            method: options.method || defaultMethod,
            extraData,
        };
        sendReportToGoogle('event', action, event);
    },
    error: (title, page, action, error, options = {}) => {
        const currentUser = getCurrentUser();
        const extraData = currentUser ? getExtraUserData(currentUser) : null;
        const event = {
            event_category: `${page} [Error]`,
            event_label: `${action} : ${error}`,
            method: options.method || defaultMethod,
            extraData,
        };

        sendReportToGoogle('event', title, event);
    },
};

export const ucFirst = word => {
    if (word) {
        const regex = /([^a-zA-Z]*)[\w]+/;
        const match = word.match(regex);
        let capitalizedWord = `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`;
        if (match && match[1]) {
            const firstLetterPosition = match[1].length;
            capitalizedWord = `${word.slice(0, firstLetterPosition)}${word
                .slice(firstLetterPosition, firstLetterPosition + 1)
                .toUpperCase()}${word.slice(firstLetterPosition + 1)}`;
        }
        return capitalizedWord;
    }
    return null;
};

export const getPageTitle = (win, params = {}) => {
    const location =
        typeof win.location.pathname === 'string'
            ? win.location
            : new URL(win.location.href, window.location.href);
    const queryStringParams = new URLSearchParams(location.search);
    const pageSplit = location.pathname.replace(/(\?.*)/, '').split('/');
    const pathname = pageSplit[pageSplit.length - 1];
    const main = pathname.split('.html')[0];
    let secondary;
    let response = 'Login';

    if (main && pathname.includes('.html')) {
        response = ucFirst(main);
        if (main === 'index') {
            response = 'Login';
        }
        if (main === 'proposal_builder') {
            response = 'Proposal Builder';
        }
        if (main === 'manage') {
            secondary = queryStringParams.get('type') || 'Package';
            response = `${response} ${ucFirst(secondary)}`;
        }
        if (main === 'package_builder') {
            response = 'Package Builder';
            if (queryStringParams.get('inventory_type')) {
                response = `Package Builder - ${ucFirst(queryStringParams.get('inventory_type'))}`;
            }
        }
        if (main === 'inventory') {
            secondary = queryStringParams.get('inventory_type') || 'Digital';
            response = `${response} - ${ucFirst(secondary)}`;
        }
        if (main === 'digital_inventory') {
            response = 'Inventory - Digital';
        }
        if (main === 'domain_settings') {
            response = 'Domain Settings';
        }
        if (main === 'not_authorized') {
            response = 'Not Authorized';
        }
        if (main === 'unexpected_failure') {
            response = 'Unexpected Failure';
        }
        if (main === 'embedded_proposal') {
            response = 'Embedded Proposal Builder';
        }
        if (main === 'no_access') {
            response = 'No Access';
        }
    }

    if (params.suffix) {
        response = `${response} ${params.suffix}`;
    }
    return response.replace('#', '');
};

export default EventUtils;
