import commonTranslations from './i18n.fr';
import dashboardTranslations from '../../dashboard/i18n.fr';
import domainSettingsTranslations from '../../domain_settings/i18n.fr';
import inventoryTranslations from '../../inventory/i18n.fr';
import loginTranslations from '../../login/i18n.fr';
import manageTranslations from '../../manage/i18n.fr';

// React only
import AvailabilityCheck from '../../../../src-react/screens/ProposalBuilder/AvailabilityCheck/i18n.en';

export default {
    ...commonTranslations,
    ...AvailabilityCheck,
    dashboard: dashboardTranslations,
    domainSettings: domainSettingsTranslations,
    inventory: inventoryTranslations,
    login: loginTranslations,
    manage: manageTranslations,
};
