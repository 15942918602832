import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import Utils from '../../../src/js/common/utils';
import '../../css/global.css';
import App from './app';
import RootReducers from './store/reducers/index';

const initialState = {};
const store = createStore(RootReducers, initialState);

const urlParams = Utils.parseQueryString(true);

ReactDOM.render(
    <Provider store={store}>
        <App urlParams={urlParams} />
    </Provider>,
    document.getElementById('react_container')
);
