import createInitialState from '../initialState';
import { selectPrice, selectInventoryItems, unselectInventoryItems } from './utils';
import INVENTORY_TYPE from '../../utils/inventoryType.utils';

import {
    ADD_CRITERION_FILTER,
    ADD_CUSTOM_SCREEN_FILTER,
    ADD_CONTEXTUAL_TARGETING_FILTER,
    ADD_SCREEN_FORMAT_FILTER,
    ADD_SCREEN_NAME_FILTER,
    APPLY_AD_SETTINGS,
    BEGIN_INVENTORY_LOADING,
    BEGIN_THIRD_PARTY_CPM_LOADING,
    CLEAR_CART_ERROR,
    CLEAR_INVENTORY,
    CLEAR_LIGHTBOX_ERROR,
    CLEAR_SELECTED_INVENTORY_ITEMS,
    CLEAR_TYPE_OF_BUY_ERRORS,
    CLOSE_PROPOSAL_GOAL_MODAL,
    CLOSE_PROPOSAL_ITEM_LIGHTBOX,
    DELETE_ALL_FILTERS,
    DELETE_FILTER,
    END_INVENTORY_LOADING,
    END_THIRD_PARTY_CPM_LOADING,
    GET_SUMMARY_DATA,
    GET_SUMMARY_DATA_ERROR,
    GET_THIRD_PARTY_CPM_DATA,
    GET_THIRD_PARTY_CPM_ERROR,
    OPEN_PROPOSAL_GOAL_MODAL,
    OPEN_PROPOSAL_ITEM_LIGHTBOX,
    RESET_SUMMARY_DATA,
    RESET_THIRD_PARTY_CPM_DATA,
    RETRIEVE_INVENTORY,
    ROLLBACK_CART,
    SAVE_PROPOSAL_ITEM_BEGIN,
    SAVE_PROPOSAL_ITEM_END,
    SELECT_INVENTORY_ITEMS,
    SET_ACTIVE_TYPE_OF_BUY,
    SET_AD_SETTINGS_DIRTY,
    SET_AVAILABLE_ONLY,
    SET_CART_DATA,
    BEGIN_SET_CART_DATA,
    END_SET_CART_DATA,
    SET_DEPLOYED_ONLY,
    SET_FLIGHT_DURATION,
    SET_INVENTORY_TYPE,
    SET_LIGHTBOX_ERROR,
    SET_MATCHING_COUNT_UNIT,
    SET_PROPOSAL_DATA,
    SET_PROPOSAL_GOAL_DATA,
    SET_PROPOSAL_ITEM_DATA,
    SET_PROPOSAL_ITEM_NAME,
    SET_TYPE_OF_BUY_ERROR,
    SET_TYPE_OF_BUY_VALUES,
    SET_USER_DATA,
    SET_WRITE_LEVEL,
    UNSELECT_INVENTORY_ITEMS,
    UPDATE_SORT_BY,
    UPDATE_SUMMARY_COUNT,
} from '../../actions/actionTypes';
import { TYPES_OF_BUY } from '../../utils/constant.utils';
import {
    MATCHING_COUNT_UNITS,
    CRITERIA_OPERATORS,
    setThirdPartyValue,
    shouldUseThirdPartyValue,
    getCurrentCPMOverrideType,
} from '../../utils/inventory.utils';

const addFilter = ({ newFilter, filtersList: oldFiltersList, equality }) => {
    let toAppend = true;
    const newFiltersList = oldFiltersList.map(f => {
        if (equality(f)) {
            toAppend = false;
            return newFilter;
        }
        return f;
    });
    if (toAppend) {
        newFiltersList.push(newFilter);
    }
    return newFiltersList;
};

const createDefaultMode = ({ defaultSovValue, cpmOverride }) => {
    let defaultDigitalMode = Object.keys(TYPES_OF_BUY).reduce((aggregator, tob) => {
        aggregator.push({
            type: TYPES_OF_BUY[tob].type,
            values: TYPES_OF_BUY[tob].defaultValues,
            active: TYPES_OF_BUY[tob] === TYPES_OF_BUY.frequency,
        });

        return aggregator;
    }, []);

    if (defaultSovValue) {
        defaultDigitalMode = defaultDigitalMode.map(m => {
            return [TYPES_OF_BUY.avgsov.type, TYPES_OF_BUY.sov.type].includes(m.type)
                ? { ...m, values: { ...TYPES_OF_BUY(m.type).defaultValues, sov: defaultSovValue } }
                : m;
        });
    }
    if (cpmOverride) {
        defaultDigitalMode = defaultDigitalMode.map(typeOfBuy => {
            const m = { ...typeOfBuy, values: { ...typeOfBuy.values, custom_cpm: 0 } };
            delete m.values.cpm;
            return m;
        });
    }

    return {
        [INVENTORY_TYPE.DIGITAL]: defaultDigitalMode,
        [INVENTORY_TYPE.STATIC]: [
            {
                type: TYPES_OF_BUY.frequency.type,
                values: TYPES_OF_BUY.frequency.defaultValues,
                active: true,
            },
        ],
    };
};

const parseCriteriaOperators = filters => {
    return filters.map(f => {
        if (typeof f === 'object') {
            return f.type === 'criteria'
                ? {
                      ...f,
                      operator: f.operator || CRITERIA_OPERATORS.AND,
                  }
                : f;
        }
        return f;
    });
};

const inventoryReducer = (state = createInitialState().inventory, action) => {
    const newState = { ...state };
    switch (action.type) {
        case SET_USER_DATA: {
            newState.slotDuration = action.data.user.domain.default_slot_duration;
            newState.defaultMode = createDefaultMode({
                featureFlags: action.data.user.feature_flags || [],
                defaultSovValue: action.data.user.domain.default_sov_value,
                cpmOverride: action.data.user.domain.cpm_override,
            });
            break;
        }
        case RETRIEVE_INVENTORY: {
            let { gridRows } = newState;
            if (gridRows.length < action.startIndex) {
                gridRows = gridRows.concat(Array(action.startIndex - gridRows.length));
            }
            const newGridRows = [];
            const allItems = { ...newState.inventoryItems };
            action.data.forEach(datum => {
                newGridRows.push(datum.id);
                allItems[datum.id] = datum;
            });
            gridRows = [
                ...gridRows.slice(0, action.startIndex),
                ...newGridRows,
                ...gridRows.slice(action.stopIndex + 1),
            ];
            newState.matchingTotal = action.total;
            newState.matchingTotals = {
                [MATCHING_COUNT_UNITS.GROUPS]: action.totalGroup,
                [MATCHING_COUNT_UNITS.SCREENS]: action.totalScreen,
            };
            newState.unfilteredCount = action.unfilteredCount;
            newState.unfilteredCounts = {
                [MATCHING_COUNT_UNITS.GROUPS]: action.unfilteredGroupCount,
                [MATCHING_COUNT_UNITS.SCREENS]: action.unfilteredScreenCount,
            };
            newState.gridRows = gridRows;
            newState.inventoryItems = allItems;
            break;
        }
        case BEGIN_INVENTORY_LOADING:
            newState.isInventoryDataLoading = true;
            break;
        case END_INVENTORY_LOADING:
            newState.isInventoryDataLoading = false;
            break;
        case UPDATE_SORT_BY: {
            newState.sortBy = action.payload.map(entry => {
                return {
                    ...entry,
                };
            });
            break;
        }
        case SET_FLIGHT_DURATION:
            newState.flightDuration = {
                ...newState.flightDuration,
                start_date: action.payload.startDate || newState.flightDuration.start_date,
                end_date: action.payload.endDate || newState.flightDuration.end_date,
                multiplier:
                    action.payload.flightDurationMultiplier || newState.flightDuration.multiplier,
                dow_mask: action.payload.dowMask || newState.flightDuration.dow_mask,
                start_time: action.payload.startTime || newState.flightDuration.start_time,
                end_time: action.payload.endTime || newState.flightDuration.end_time,
            };
            break;
        case SET_TYPE_OF_BUY_ERROR: {
            newState.typeOfBuyErrors = {
                ...newState.typeOfBuyErrors,
                ...action.payload,
            };
            break;
        }
        case CLEAR_CART_ERROR: {
            newState.cartError = false;
            break;
        }
        case CLEAR_TYPE_OF_BUY_ERRORS: {
            newState.typeOfBuyErrors = {};
            break;
        }
        case CLEAR_INVENTORY: {
            newState.matchingTotal = undefined;
            newState.unfilteredCount = undefined;
            newState.gridRows = [];
            newState.inventoryItems = {};
            break;
        }
        case CLEAR_SELECTED_INVENTORY_ITEMS: {
            newState.selectedInventoryItemIds = {};
            break;
        }
        case SET_CART_DATA:
            if (!newState.cart.id) {
                const normalizedFilters = action.payload.filters || [];

                newState.filters = parseCriteriaOperators(normalizedFilters);

                newState.selectedInventoryItemIds = { ...newState.selectedInventoryItemIds };
                action.payload.screens.forEach(id => {
                    newState.selectedInventoryItemIds[id] = true;
                });
            }
            newState.cart = { ...action.payload };
            break;
        case BEGIN_SET_CART_DATA:
        case END_SET_CART_DATA:
            newState.cart = { ...newState.cart, ...action.payload };
            break;
        case ROLLBACK_CART:
            newState.selectedInventoryItemIds = {};
            newState.cart.screens.forEach(id => {
                newState.selectedInventoryItemIds[id] = true;
            });
            newState.cartError = true;
            break;
        case SELECT_INVENTORY_ITEMS:
            newState.selectedInventoryItemIds = selectInventoryItems(
                { ...newState.selectedInventoryItemIds },
                action.payload.selectedIds
            );
            break;
        case UNSELECT_INVENTORY_ITEMS:
            newState.selectedInventoryItemIds = unselectInventoryItems(
                { ...newState.selectedInventoryItemIds },
                action.payload.unselectedIds
            );
            break;
        case UPDATE_SUMMARY_COUNT:
            newState.summary = {
                ...newState.summary,
                groupCount: action.payload.group_count,
                screenCount: action.payload.screen_count,
            };
            break;
        case GET_SUMMARY_DATA:
            newState.summary = {
                groupCount: action.payload.group_count,
                screenCount: action.payload.screen_count,
                cpm: action.payload.cpm,
                impressions: action.payload.audience_count,
                budget: selectPrice(action.payload),
                shareOfVoice: action.payload.sov,
                repetitions: action.payload.repetitions,
            };
            break;
        case GET_SUMMARY_DATA_ERROR: {
            newState.summary = {
                groupCount: undefined,
                screenCount: undefined,
                cpm: undefined,
                impressions: undefined,
                budget: undefined,
                shareOfVoice: undefined,
                repetitions: undefined,
            };
            break;
        }
        case RESET_SUMMARY_DATA: {
            newState.summary = {
                ...newState.summary,
                cpm: undefined,
                impressions: undefined,
                budget: undefined,
                shareOfVoice: undefined,
                repetitions: undefined,
            };
            break;
        }
        case SET_ACTIVE_TYPE_OF_BUY: {
            let activeEntry;
            newState.mode = newState.mode.map(typeOfBuy => {
                const entry = { ...typeOfBuy };
                entry.active = false;
                if (
                    typeOfBuy.type === action.payload.type ||
                    (!activeEntry && typeOfBuy.type === TYPES_OF_BUY.frequency.type)
                ) {
                    activeEntry = entry;
                }
                return entry;
            });
            activeEntry.active = true;
            break;
        }
        case SET_TYPE_OF_BUY_VALUES: {
            newState.mode = newState.mode.map(typeOfBuy => {
                const entry = { ...typeOfBuy };
                if (typeOfBuy.type === action.payload.type) {
                    const propsNamesInPayload = Object.keys(action.payload.values);
                    const propsNamesIncluded = Object.keys(typeOfBuy.values).every(prop => {
                        return propsNamesInPayload.includes(prop);
                    });
                    if (propsNamesIncluded) {
                        entry.values = action.payload.values;
                    }
                }
                return entry;
            });
            break;
        }
        case ADD_CRITERION_FILTER: {
            const newFilter = {
                uuid: `criteria:${action.payload.id}`,
                id: action.payload.id,
                type: 'criteria',
                name: action.payload.name,
                operator: action.payload.operator || CRITERIA_OPERATORS.AND,
            };

            newState.filters = addFilter({
                newFilter,
                filtersList: newState.filters,
                equality: f => f.type === 'criteria' && f.id === newFilter.id,
            });

            break;
        }
        case ADD_CUSTOM_SCREEN_FILTER: {
            const newFilter = {
                active: true,
                importType: action.payload.target,
                type: 'custom_screens',
                name: 'Imported list',
                uuid: 'custom_screens:custom_screens',
                data: action.payload,
            };

            newState.filters = addFilter({
                newFilter,
                filtersList: newState.filters,
                equality: f => f.type === 'custom_screens',
            });

            break;
        }
        case ADD_CONTEXTUAL_TARGETING_FILTER: {
            const newFilter = {
                uuid: 'contextual_targeting:contextual_targeting',
                type: 'contextual_targeting',
                name: 'Contextual Filter',
                data: action.payload,
            };

            newState.filters = addFilter({
                newFilter,
                filtersList: newState.filters,
                equality: f => f.type === 'contextual_targeting',
            });

            break;
        }
        case ADD_SCREEN_NAME_FILTER: {
            const newFilter = {
                type: 'keyword',
                name: action.payload.value,
                uuid: `keyword:${action.payload.value}`,
            };
            newState.filters = addFilter({
                newFilter,
                filtersList: newState.filters,
                equality: f => f.type === 'keyword' && f.name === newFilter.name,
            });
            break;
        }
        case ADD_SCREEN_FORMAT_FILTER: {
            const newFilter = {
                type: 'screen_format',
                name: action.payload.value,
                uuid: `screen_format:${action.payload.value}`,
                id: action.payload.value,
            };
            newState.filters = addFilter({
                newFilter,
                filtersList: newState.filters,
                equality: f => f.type === 'screen_format' && f.name === newFilter.name,
            });
            break;
        }
        case DELETE_FILTER: {
            newState.filters = newState.filters.filter(
                ({ type, name }) => !(type === action.payload.type && name === action.payload.name)
            );
            break;
        }
        case DELETE_ALL_FILTERS: {
            newState.filters = [];
            break;
        }
        case SET_DEPLOYED_ONLY: {
            newState.deployedOnly = action.payload.deployedOnly;
            break;
        }
        case SET_AVAILABLE_ONLY: {
            newState.availableOnly = action.payload.availableOnly;
            break;
        }
        case APPLY_AD_SETTINGS: {
            newState.slotDuration = action.payload.settings.slotDuration;
            newState.sharedProposalGoalToggle = action.payload.settings.sharedProposalGoalToggle;
            newState.sharedProposalGoalId = action.payload.settings.sharedProposalGoalId;
            newState.dynamicScreenQuantity = action.payload.settings.dynamicScreenQuantity;
            const { flightDuration } = action.payload.settings;
            const { customPrice, customCPM } = setThirdPartyValue(
                action.payload.settings.customCPM,
                action.payload.settings.customPrice
            );
            newState.flightDuration = {
                ...newState.flightDuration,
                start_date: flightDuration.startDate,
                end_date: flightDuration.endDate,
                multiplier: flightDuration.flightDurationMultiplier,
                dow_mask: flightDuration.dowMask,
                start_time: flightDuration.startTime,
                end_time: flightDuration.endTime,
            };
            newState.mode = [...action.payload.settings.typeOfBuys];
            newState.customPrice = customPrice;
            newState.customCPM = customCPM;
            break;
        }
        case SET_LIGHTBOX_ERROR: {
            const {
                payload: { error },
            } = action;
            newState.lightbox = {
                ...newState.lightbox,
                error,
            };
            break;
        }
        case CLEAR_LIGHTBOX_ERROR: {
            const { error, ...lightbox } = newState.lightbox;
            newState.lightbox = lightbox;
            break;
        }
        case SAVE_PROPOSAL_ITEM_BEGIN: {
            newState.lightbox = {
                ...newState.lightbox,
                isSaving: true,
            };
            break;
        }
        case SAVE_PROPOSAL_ITEM_END: {
            newState.lightbox = {
                ...newState.lightbox,
                isSaving: false,
            };
            break;
        }
        case SET_PROPOSAL_DATA: {
            const { payload } = action;
            newState.categoryIds = payload.category_ids || [];
            break;
        }
        case SET_PROPOSAL_ITEM_DATA: {
            const { payload } = action;
            newState.proposalItem = { ...payload };
            break;
        }
        case OPEN_PROPOSAL_GOAL_MODAL: {
            newState.isProposalGoalModalOpen = true;
            break;
        }
        case CLOSE_PROPOSAL_GOAL_MODAL: {
            newState.isProposalGoalModalOpen = false;
            break;
        }
        case SET_PROPOSAL_GOAL_DATA: {
            const { payload } = action;
            if (Object.keys(payload).length) {
                newState.proposalGoal = { ...state.proposalGoal, ...payload };
            } else {
                newState.proposalGoal = { ...payload };
            }
            break;
        }
        case SET_PROPOSAL_ITEM_NAME: {
            const { payload } = action;
            newState.lightbox = {
                ...newState.lightbox,
                name: payload,
            };
            break;
        }
        case OPEN_PROPOSAL_ITEM_LIGHTBOX: {
            const { proposalItem } = newState;
            const { customPrice, customCPM } = setThirdPartyValue(
                proposalItem.custom_cpm,
                proposalItem.custom_price
            );

            if (
                proposalItem.dynamic_screen_selection !== undefined &&
                proposalItem.dynamic_screen_selection !== null
            ) {
                proposalItem.isDynamicSelection = true;
            }
            if (proposalItem.isDynamicSelection) {
                newState.dynamicScreenQuantity =
                    proposalItem.dynamic_screen_selection &&
                    proposalItem.dynamic_screen_selection.quantity
                        ? proposalItem.dynamic_screen_selection.quantity
                        : 1;
            }

            newState.slotDuration = proposalItem.slot_duration || newState.slotDuration;
            newState.customPrice = customPrice;
            newState.customCPM = customCPM;
            newState.sharedProposalGoalId = null;
            newState.flightDuration = {
                ...newState.flightDuration,
                start_date: proposalItem.start_date || newState.flightDuration.start_date,
                end_date: proposalItem.end_date || newState.flightDuration.end_date,
                start_time: proposalItem.start_time || '00:00:00',
                end_time: proposalItem.end_time || '23:59:59',
                dow_mask: proposalItem.dow_mask || newState.flightDuration.dow_mask,
                flight_duration:
                    proposalItem.flight_duration || newState.flightDuration.flight_duration,
                flight_type: !Number.isNaN(proposalItem.flight_type)
                    ? proposalItem.flight_type
                    : newState.flightDuration.flight_type,
                multiplier: proposalItem.multiplier || newState.flightDuration.multiplier,
            };
            newState.selectedInventoryItemIds = selectInventoryItems(
                {},
                proposalItem.screens || []
            );
            newState.inventoryType = proposalItem.inventory_type || newState.inventoryType;

            const defaultMode = newState.defaultMode[newState.inventoryType];
            const piModeByType = (proposalItem.mode || []).reduce((aggregator, m) => {
                // eslint-disable-next-line no-param-reassign
                aggregator[m.type] = m;
                return aggregator;
            }, {});

            newState.sharedProposalGoalToggle = Boolean(proposalItem.proposal_goal_id);
            newState.sharedProposalGoalId = proposalItem.proposal_goal_id;

            newState.mode = defaultMode.reduce((aggregator, defaultM) => {
                const piM = { ...(piModeByType[defaultM.type] || defaultM) };
                const valueNames = Object.keys(piM.values || {});
                Object.keys(defaultM.values).forEach(valName => {
                    if (!valueNames.includes(valName)) {
                        piM.values[valName] = defaultM.values[valName];
                    }
                });
                aggregator.push(piM);
                return aggregator;
            }, []);

            newState.filters = proposalItem.filters || [];
            newState.lightbox = {
                ...newState.lightbox,
                isOpen: true,
                name: proposalItem.name || '',
                isPreemptible: Boolean(proposalItem.is_preemptible),
            };
            newState.availableOnly = false;
            newState.deployedOnly = false;

            break;
        }
        case CLOSE_PROPOSAL_ITEM_LIGHTBOX:
            newState.lightbox = {};
            newState.proposalItem = {};
            newState.thirdPartyCpm = {};
            break;
        case SET_WRITE_LEVEL: {
            newState.lightbox.writeLevel = action.payload.writeLevel;
            break;
        }
        case SET_MATCHING_COUNT_UNIT: {
            newState.matchingCountUnit = action.payload.unit;
            break;
        }
        case SET_INVENTORY_TYPE: {
            newState.inventoryType = action.payload;
            newState.mode = [...newState.defaultMode[newState.inventoryType]];
            break;
        }
        case SET_AD_SETTINGS_DIRTY: {
            newState.isAdSettingsDirty = action.payload;
            break;
        }
        case BEGIN_THIRD_PARTY_CPM_LOADING: {
            newState.thirdPartyCpm = {
                ...newState.thirdPartyCpm,
                loading: true,
                cancelTokenSource: action.payload.cancelTokenSource,
            };
            break;
        }
        case END_THIRD_PARTY_CPM_LOADING: {
            newState.thirdPartyCpm = {
                ...newState.thirdPartyCpm,
                loading: false,
                cancelTokenSource: undefined,
            };
            break;
        }
        case GET_THIRD_PARTY_CPM_DATA: {
            if (
                action.payload.override_type === 'price' &&
                shouldUseThirdPartyValue(newState, action.payload)
            ) {
                newState.customPrice = action.payload.value;
                newState.customCPM = undefined;
            } else if (
                action.payload.override_type === 'cpm' &&
                shouldUseThirdPartyValue(newState, action.payload)
            ) {
                newState.customCPM = action.payload.value;
                newState.customPrice = undefined;
            }
            newState.thirdPartyCpm = {
                error: {},
                // eslint-disable-next-line camelcase
                cpm_override_type: action.payload.override_type,
                cpm_value: action.payload.value,
            };
            break;
        }
        case GET_THIRD_PARTY_CPM_ERROR: {
            const { data } = action.payload || {};
            // eslint-disable-next-line camelcase
            const cpm_override_type = getCurrentCPMOverrideType(newState);

            newState.thirdPartyCpm = {
                ...newState.thirdPartyCpm,
                cpm_override_type,
                error: {
                    code: data?.error?.code ?? 'UNHANDLED',
                    // eslint-disable-next-line camelcase
                    errorMessage: data?.error?.error_message ?? '',
                },
            };
            break;
        }
        case RESET_THIRD_PARTY_CPM_DATA: {
            newState.thirdPartyCpm = {
                ...createInitialState().inventory.thirdPartyCpm,
            };
            break;
        }
        default:
            break;
    }
    return newState;
};

export default inventoryReducer;
