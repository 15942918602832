import React, { Component } from 'react';
import { detect } from 'detect-browser';

import Image from '../../../components/UI/Image/Image';
import I18n from '../../../../src/js/common/i18n/I18nSt';

import Footer from '../../../components/UI/Footer/Footer';
import Login from '../../../components/UI/Login/Login';
import s from './loginView.css';
import Utils from '../../../../src/js/common/utils';

I18n.initLanguages();

class LoginView extends Component {
    constructor(props) {
        super(props);
        const browser = detect();
        this.isIe11 = browser && browser.name === 'ie' && Number(browser.version.slice(0, 2)) <= 11;
    }

    render() {
        return (
            <div className={s.background}>
                {this.isIe11 && (
                    <div className={s.ie11Disclaimer}>
                        {I18n.get('login.Ie11Disclaimer')}
                        &nbsp;
                        <a
                            href="https://broadsign.com/docs/broadsign-direct/interface.html"
                            className={s.ie11Link}
                        >
                            {I18n.get('login.Ie11DisclaimerLink')}
                        </a>
                    </div>
                )}
                <div className={s.main_container}>
                    <div className={s.gridLogo}>
                        <div id="logo" className={s.Logo_container}>
                            <Image
                                className={s.logo}
                                src="assets/images/direct-logo.png"
                                alt="BroadSign Logo"
                            />
                        </div>
                    </div>
                    <div className={s.gridLogin}>
                        <Login
                            urlParams={Utils.parseQueryString(true)}
                            isOutdatedBrowser={this.isIe11}
                        />
                    </div>
                    <div className={s.gridFooter}>
                        <Footer text={I18n.get('login.Footer')} />
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginView;
