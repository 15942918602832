export default {
    Loading: 'Loading',
    LblEmail: 'Email address',
    LblPassword: 'Password',
    BtnLogin: 'Login',
    EmailInvalid: 'You have entered an invalid email',
    EmailMissing: 'Please enter your email',
    PasswordMissing: 'Please enter your password',
    EmptyCreds: 'Please enter your email and password',
    LoginFailed: 'Login failed',
    LogoutSuccessfully: 'You have been successfully logged out.',
    LoginEmailPlaceholder: 'user@company.com',
    LoginPasswordPlaceholder: 'Enter your password',
    LoginWelcome: 'Welcome!',
    Footer: '© Broadsign International, LLC. All rights reserved.',
    Ie11Disclaimer:
        'This browser is no longer supported, you can use Chrome, Edge, Safari or Firefox.',
    Ie11DisclaimerLink: 'Click here for more info.',
};
