export default {
    BtnNewProposal: 'New Proposal',
    BtnNewDigitalPackage: 'Digital Package',
    BtnNewStaticPackage: 'Static Package',
    Name: 'Name',
    Price: 'Price',
    SeeAll: 'See all >>',
    MostPopularPackages: 'Most Popular Packages',
    NoPackages: 'No packages to display yet',
    ProposalFromPackage: 'Create new proposal from this package',
    ToastProposalFromPackageSaved:
        'Proposal was successfully created.<a href="/manage.html?type=proposal"> See on Proposals page</a>.',
    ToastProposalSaveFailed: 'Failed to save a proposal. Please try again.',
    LblAvailableAudience: 'Demographic',
    LblAvailableImpressions: 'Impressions',
    ErrorFetchAudienceBreakdownData:
        'Failed to fetch audience data. Please try reloading the page.',
    LblNoDataAvailable: 'No data available',
    LblToday: 'Today',
    LblImpressions: 'Impressions',
    LatestProposals: 'Last Updated Proposals',
};
