import { TOGGLE_LOADING } from '../actions/actionTypes';

const toggleLoading = (state = { loading: false }, action) => {
    if (action.type === TOGGLE_LOADING) {
        return { loading: action.loading };
    }
    return state;
};

export default toggleLoading;
