const fonts = {
    brandFont: '"Roboto", Helvetica, Arial, sans-serif',
    lato: '"Lato", Helvetica, Arial, sans-serif',
    defaultFontSize: '14px',
    largeFontSize: '16px',
    promptFontSize: '13px',
    promptFontWeight: '300',
    smallFontSize: '12px',
};

module.exports = fonts;
