import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    toggleLoading as toggleLoadingAction,
    setEmail as setEmailAction,
    setPassword as setPasswordAction,
    setLoginError as setLoginErrorAction,
    setPasswordError as setPasswordErrorAction,
} from '../../../screens/Login/store/actions/login';
import I18n from '../../../../src/js/common/i18n/I18nSt';
import Input from '../Input/Input';
import { unitSize } from '../../../utils/style.utils';
import ButtonSpinner from '../Button/ButtonSpinner';
import s from './styles.css';
import { validateEmail } from '../../../utils/validation.utils';
import Events, { getPageTitle } from '../../../utils/Events';

I18n.initLanguages();
class Login extends React.PureComponent {
    constructor(props) {
        super(props);

        this.redirectUrls = [
            'index.html',
            'inventory.html',
            'dashboard.html',
            'package_builder.html',
            'proposal_builder.html',
            'manage.html',
            'digital_inventory.html',
        ];
        this.handleKeyEnter = this.handleKeyEnter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
    }

    formValid() {
        let isValid = true;
        const { password, email, setLoginError, setPasswordError } = this.props;
        if (password.length === 0 && email.length === 0) {
            setLoginError(I18n.get('login.EmptyCreds'));
            isValid = false;
            Events.error(
                'Login empty credentials',
                getPageTitle(window),
                'submit form',
                'empty credentials'
            );
        } else {
            if (password.length === 0) {
                setPasswordError(I18n.get('login.PasswordMissing'));
                isValid = false;
                Events.error(
                    'Login password missing',
                    getPageTitle(window),
                    'submit form',
                    'missing password'
                );
            }
            if (email.length === 0) {
                setLoginError(I18n.get('login.EmailMissing'));
                isValid = false;
                Events.error(
                    'Login email missing',
                    getPageTitle(window),
                    'submit form',
                    'missing email'
                );
            }
        }
        return isValid;
    }

    handleChange(event) {
        const { setLoginError, setPasswordError, setEmail, setPassword } = this.props;
        setLoginError('');
        setPasswordError('');
        switch (event.target.name) {
            case 'email':
                setEmail(event.target.value);
                break;
            case 'password':
                setPassword(event.target.value);
                break;
            default:
                break;
        }
    }

    validateEmail(event) {
        const { setLoginError } = this.props;
        if (event.target.value) {
            setLoginError(!validateEmail(event.target.value) ? I18n.get('login.EmailInvalid') : '');

            if (!validateEmail(event.target.value)) {
                Events.error(
                    'Login email invalid',
                    getPageTitle(window),
                    'typing email',
                    'invalid email'
                );
            }
        }
    }

    handleKeyEnter(event) {
        if (event.keyCode === 13) {
            this.handleSubmit(event);
        }
    }

    handleSubmit(event) {
        const { email, password, toggleLoading, setLoginError, setPasswordError } = this.props;
        event.preventDefault();
        Events.action('Login submit form', getPageTitle(window), 'submit form');
        if (this.formValid()) {
            toggleLoading(true);
            axios
                .post('/login', { email, password })
                .then(() => {
                    this.forwardToRedirect();
                })
                .catch(() => {
                    setLoginError(I18n.get('login.LoginFailed'));
                    setPasswordError('');
                    toggleLoading(false);
                    Events.error(
                        'Login failed',
                        getPageTitle(window),
                        'authentication',
                        'login failed'
                    );
                });
        }
    }

    forwardToRedirect() {
        const { urlParams } = this.props;
        if (
            urlParams.redirect &&
            this.redirectUrls.indexOf(urlParams.redirect.split('?')[0]) >= 0
        ) {
            window.location.assign(urlParams.redirect);
            Events.switchPage(getPageTitle(window), urlParams.redirect.replace(/\.html/, ''));
        } else {
            window.location.assign('/dashboard.html');
            Events.switchPage(getPageTitle(window), 'Dashboard');
        }
    }

    render() {
        const {
            loginError,
            email,
            passwordError,
            isOutdatedBrowser,
            urlParams,
            password,
            loading,
        } = this.props;
        const logout =
            urlParams.action === 'logout' ? (
                <div className={s.login_info}>{I18n.get('login.LogoutSuccessfully')}</div>
            ) : (
                undefined
            );
        return (
            <div className={s.container}>
                {logout}
                <div className={s.login_container}>
                    <div className={s.email}>
                        <Input
                            className={s.login}
                            autoFocus
                            onChange={this.handleChange}
                            value={email}
                            onBlur={this.validateEmail}
                            type="email"
                            name="email"
                            customStyle={{
                                borderRadius: unitSize(5),
                            }}
                            onKeyDown={this.handleKeyEnter}
                            height={50}
                            width={540}
                            labelText="Email address"
                            placeholder={I18n.get('login.LoginEmailPlaceholder')}
                            labelPosition="top"
                            error={loginError}
                            dataTest="cy-input-email"
                            disabled={isOutdatedBrowser}
                        />
                    </div>
                    <div className={s.password}>
                        <Input
                            className={s.login}
                            onChange={this.handleChange}
                            value={password}
                            name="password"
                            type="password"
                            customStyle={{
                                borderRadius: unitSize(5),
                            }}
                            height={50}
                            width={540}
                            labelText="Password"
                            placeholder={I18n.get('login.LoginPasswordPlaceholder')}
                            onKeyDown={this.handleKeyEnter}
                            error={passwordError}
                            labelPosition="top"
                            dataTest="cy-input-password"
                            disabled={isOutdatedBrowser}
                        />
                    </div>
                    <ButtonSpinner
                        label={I18n.get('login.BtnLogin')}
                        className="primary"
                        width={200}
                        onClick={this.handleSubmit}
                        isLoading={loading}
                        loadingText={I18n.get('login.Loading')}
                        dataTest="cy-submit-button"
                        disabled={isOutdatedBrowser}
                    />
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    urlParams: PropTypes.objectOf(PropTypes.string),
    setPassword: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    loginError: PropTypes.string.isRequired,
    passwordError: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    setLoginError: PropTypes.func.isRequired,
    setPasswordError: PropTypes.func.isRequired,
    toggleLoading: PropTypes.func.isRequired,
    isOutdatedBrowser: PropTypes.bool,
};

Login.defaultProps = {
    urlParams: {
        redirect: null,
        action: null,
    },
    isOutdatedBrowser: false,
};

const mapStateToProps = state => {
    const props = {
        email: state.login.formValues.email,
        password: state.login.formValues.password,
        loginError: state.login.errors.loginError,
        passwordError: state.login.errors.passwordError,
        loading: state.common.loading,
    };
    return props;
};

const mapDispatchToProps = dispatch => {
    const toDispatch = {
        toggleLoading: isLoading => {
            dispatch(toggleLoadingAction(isLoading));
        },
        setEmail: value => {
            dispatch(setEmailAction(value));
        },
        setPassword: value => {
            dispatch(setPasswordAction(value));
        },
        setLoginError: value => {
            dispatch(setLoginErrorAction(value));
        },
        setPasswordError: value => {
            dispatch(setPasswordErrorAction(value));
        },
    };
    return toDispatch;
};

const LoginConnect = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

export default LoginConnect;
