export default {
    ColumnHeaderActions: 'Actions',
    BtnView: 'View',
    BtnEdit: 'Edit',
    BtnClone: 'Clone',
    BtnDelete: 'Delete',
    Published: 'Published',
    Unpublished: 'Unpublished',
    FlightDuration: 'Flight duration',
    SlotDuration: 'Ad duration',
    Price: 'Price',
    LastUpdate: 'Last update',
    Status: 'Status',
    Name: 'Name',
    CreationDateTime: 'Creation date',
    SearchPlaceholder: 'What are you looking for?',
    Version: 'Version',
    Owner: 'Owner',
    Client: 'Client',
    BtnNewDigitalPackage: 'New Digital Package',
    BtnNewProposal: 'New Proposal',
    BtnNewStaticPackage: 'New Static Package',
    ProposalFromPackage: 'Create new proposal from package selection',
    ToastProposalFromPackageSaved:
        'Proposal was successfully created.<a href="/manage.html?type=proposal"> See on Proposals page</a>.',
    ToastProposalFromPackageFailed: 'Unable to save this proposal',
    TextUserProposalsOnly: 'Show my proposals',
};
