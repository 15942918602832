import commonTranslations from './i18n.platform';
import dashboardTranslations from '../../dashboard/i18n.en';
import domainSettingsPlatformTranslations from '../../domain_settings/i18n.en.platform';
import inventoryTranslations from '../../inventory/i18n.en';
import loginTranslations from '../../login/i18n.en';
import manageTranslations from '../../manage/i18n.en';

// React only
import AvailabilityCheck from '../../../../src-react/screens/ProposalBuilder/AvailabilityCheck/i18n.platform';

export default {
    ...commonTranslations,
    ...AvailabilityCheck,
    dashboard: dashboardTranslations,
    domainSettings: domainSettingsPlatformTranslations,
    inventory: inventoryTranslations,
    login: loginTranslations,
    manage: manageTranslations,
};
