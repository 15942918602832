import { isEqual, isNil } from 'lodash';
import { TYPES_OF_BUY } from './constant.utils';
import INVENTORY_TYPE_ENUM from './inventoryType.utils';

export const INVENTORY_TYPE = INVENTORY_TYPE_ENUM;

export const MATCHING_COUNT_UNITS = {
    GROUPS: 'groups',
    SCREENS: 'screens',
};

export const CRITERIA_OPERATORS = {
    AND: 'and',
    NOT: 'not',
};

export const isImpressionsMode = mode => mode.type === TYPES_OF_BUY.impressions.type;

export const isBudgetMode = mode => mode.type === TYPES_OF_BUY.budget.type;

export const isPlayMode = mode => mode.type === TYPES_OF_BUY.play.type;

export const isGoalMode = mode => isBudgetMode(mode) || isImpressionsMode(mode);

export const isImpressionsSharedGoal = goal => goal.goal_type === TYPES_OF_BUY.impressions.type;

export const isBudgetSharedGoal = goal => goal.goal_type === TYPES_OF_BUY.budget.type;

export const isPlaySharedGoal = goal => goal.goal_type === TYPES_OF_BUY.play.type;

export const parseScreensCsv = (data, importType) => {
    const acceptedColumnNames = ['name', 'screen_id', 'id', 'sign_id', 'deviceId'];

    return {
        importType,
        values: data
            .split(/\r?\n/)
            .map(x => x.trim())
            .filter(
                x => x !== '' && !acceptedColumnNames.includes(x.split(',').map(y => y.trim())[0])
            ),
    };
};

export const setThirdPartyValue = (cpmValue, priceValue) => {
    if (!isNil(cpmValue)) {
        return {
            customPrice: undefined,
            customCPM: cpmValue,
        };
    }

    if (!isNil(priceValue)) {
        return {
            customPrice: priceValue,
            customCPM: undefined,
        };
    }

    return {
        customPrice: undefined,
        customCPM: undefined,
    };
};

// The logic here splits on the condition of whether a proposal item is being created or being edited/amended.
// In case of creating a proposal item, there is no proposal item stored in the backend yet.
// In the case of editing/amending, a proposal item has already been stored with a custom price or cpm so extra care
// must be taken to avoid overwriting it when the edit/amend lightbox is first opened
export const shouldUseThirdPartyValue = (state, actionPayload) => {
    if (state.lightbox && ['edit', 'amend'].includes(state.lightbox.writeLevel)) {
        // only use the price from the pricing api response if the user has changed any ad settings
        return (
            !isEqual(
                JSON.stringify(state.mode.filter(mode => mode.active)),
                JSON.stringify(state.proposalItem.mode.filter(mode => mode.active))
            ) ||
            !isEqual(state.flightDuration.flight_duration, state.proposalItem.flight_duration) ||
            !isEqual(state.flightDuration.flight_type, state.proposalItem.flight_type) ||
            !isEqual(state.flightDuration.start_date, state.proposalItem.start_date) ||
            !isEqual(state.flightDuration.start_time, state.proposalItem.start_time) ||
            !isEqual(state.flightDuration.end_date, state.proposalItem.end_date) ||
            !isEqual(state.flightDuration.end_time, state.proposalItem.end_time) ||
            !isEqual(state.flightDuration.dow_mask, state.proposalItem.dow_mask) ||
            !isEqual(state.flightDuration.multiplier, state.proposalItem.multiplier) ||
            !isEqual(state.slotDuration, state.proposalItem.slot_duration) ||
            (!isNil(state.thirdPartyCpm.cpm_value) &&
                !isEqual(state.thirdPartyCpm.cpm_value, actionPayload.value)) ||
            !isEqual(
                Object.keys(state.selectedInventoryItemIds)
                    .map(Number)
                    .sort(),
                state.proposalItem.screens.sort()
            )
        );
    }

    if (actionPayload.override_type === 'price') {
        // if user has not set any price overwrite, use the price from pricing API
        // or if the price from pricing API is different from the last pricing API response, something has changed ( user added a new screen, etc.)
        return (
            isNil(state.customPrice) ||
            (!isNil(state.thirdPartyCpm.cpm_value) &&
                !isEqual(state.thirdPartyCpm.cpm_value, actionPayload.value))
        );
    }
    if (actionPayload.override_type === 'cpm') {
        return (
            isNil(state.customCPM) ||
            (!isNil(state.thirdPartyCpm.cpm_value) &&
                !isEqual(state.thirdPartyCpm.cpm_value, actionPayload.value))
        );
    }
    return false;
};

export const getCurrentCPMOverrideType = state => {
    if (isNil(state.thirdPartyCpm.cpm_override_type)) {
        if (isNil(state.customCPM)) {
            return 'price';
        }

        return 'cpm';
    }

    return state.thirdPartyCpm.cpm_override_type;
};
