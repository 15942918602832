import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { unitSize } from '../../../utils/style.utils';
import '../../../css/icomoon.css';
import s from './icon.css';
import variables from '../../../css/variables';

/**
 * Accepted Values for Icon :
 * - key
 * - magnificence
 * - tick
 * - tick-circle
 * - dropdown-up
 * - dropdown-down
 * - delete-circle
 */
class Icon extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        const { color, size, customStyle } = this.props;
        this.myRef.current.style.color = color;
        this.myRef.current.style.fontSize = unitSize(size);
        if (Object.keys(customStyle).length > 0) {
            this.applyCustomStyle(customStyle);
        }
    }

    shouldComponentUpdate(nextProps) {
        const { color } = this.props;
        if (nextProps.color !== color) {
            this.myRef.current.style.color = nextProps.color;
            return true;
        }
        return false;
    }

    applyCustomStyle(styles) {
        Object.entries(styles).forEach(([key, value]) => {
            this.myRef.current.style[key] = value;
        });
    }

    render() {
        const { disabled, className, icon } = this.props;
        let classes = `icomoon ${s.icon} ${s[icon]}`;
        if (className) {
            classes = `${classes} ${s[className]}`;
        }
        if (disabled === true) {
            classes = `${classes} s.disabled`;
        }
        return <i ref={this.myRef} className={classes} />;
    }
}

Icon.defaultProps = {
    disabled: false,
    color: variables.iconDefaultColor,
    size: 16,
    className: undefined,
    customStyle: {},
};

Icon.propTypes = {
    disabled: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
    customStyle: PropTypes.shape({}),
};

export default Icon;
