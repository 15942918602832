import {
    TOGGLE_LOADING,
    SET_EMAIL,
    SET_PASSWORD,
    SET_LOGIN_ERROR,
    SET_PASSWORD_ERROR,
} from './actionTypes';

export const toggleLoading = isLoading => {
    const action = {
        type: TOGGLE_LOADING,
        loading: isLoading,
    };
    return action;
};

export const setEmail = value => {
    const action = {
        type: SET_EMAIL,
        value,
    };
    return action;
};

export const setPassword = value => {
    const action = {
        type: SET_PASSWORD,
        value,
    };
    return action;
};

export const setLoginError = value => {
    const action = {
        type: SET_LOGIN_ERROR,
        value,
    };
    return action;
};

export const setPasswordError = value => {
    const action = {
        type: SET_PASSWORD_ERROR,
        value,
    };
    return action;
};
