import { combineReducers } from 'redux';

import {
    SET_EMAIL,
    SET_PASSWORD,
    SET_LOGIN_ERROR,
    SET_PASSWORD_ERROR,
} from '../actions/actionTypes';

const defaultFormValues = {
    email: '',
    password: '',
};

const formValues = (state = defaultFormValues, action) => {
    switch (action.type) {
        case SET_EMAIL:
            return {
                ...state,
                email: action.value,
            };
        case SET_PASSWORD:
            return {
                ...state,
                password: action.value,
            };
        default:
            return state;
    }
};

const defaultErrors = {
    loginError: '',
    passwordError: '',
};

const errors = (state = defaultErrors, action) => {
    switch (action.type) {
        case SET_LOGIN_ERROR:
            return {
                ...state,
                loginError: action.value,
            };
        case SET_PASSWORD_ERROR:
            return {
                ...state,
                passwordError: action.value,
            };
        default:
            return state;
    }
};
export default combineReducers({ formValues, errors });
