import moment from 'moment';
import createInitialState from './initialState';
import { selectInventoryItems, unselectInventoryItems } from '../utils';

import {
    ADD_AVAILABILITY_STATUS_FILTER,
    ADD_FACE_NAME_FILTER,
    ADD_FORMAT_ID_FILTER,
    ADD_SITE_NAME_FILTER,
    BEGIN_INVENTORY_LOADING,
    CLEAR_INVENTORY,
    DELETE_STATIC_FILTER,
    DELETE_ALL_STATIC_FILTERS,
    END_INVENTORY_LOADING,
    RETRIEVE_STATIC_INVENTORY,
    UPDATE_SALES_PERIOD_FILTER,
    UPDATE_STATIC_SORT_BY,
    SELECT_STATIC_INVENTORY_ITEMS,
    UNSELECT_STATIC_INVENTORY_ITEMS,
} from '../../../actions/actionTypes';
import { AVAILABILITY_STATUS } from '../../../utils/static/constants/availability';
import applyAvailabilityFilter from './availabilityFilter/availabilityFilter';

const addFilter = ({ newFilter, filtersList: oldFiltersList, equality }) => {
    let toAppend = true;
    const newFiltersList = oldFiltersList.map(f => {
        if (equality(f)) {
            toAppend = false;
            return newFilter;
        }
        return f;
    });
    if (toAppend) {
        newFiltersList.push(newFilter);
    }
    return newFiltersList;
};

const createStringFilter = (action, type) => {
    return {
        type,
        name: action.payload.value,
    };
};

const createOptionFilter = (action, type) => {
    return {
        type,
        id: action.payload.id,
        name: action.payload.name,
    };
};

const formatDate = date => {
    return moment(date).format('YYYY-MM-DD');
};

const resetSelectionSort = state => {
    // eslint-disable-next-line no-param-reassign
    state.isFilteringOnSelectedFaces = state.sortBy.some(
        s => s.field === 'selection' && s.dir === 'asc'
    );
};

const isOnFirstPartOfSelectionSorting = state => {
    const selectionSort = state.sortBy.find(s => s.field === 'selection');
    if (selectionSort === undefined) return false;

    if (selectionSort.dir === 'asc' && !state.isFilteringOnSelectedFaces) return false;

    if (selectionSort.dir === 'desc' && state.isFilteringOnSelectedFaces) return false;

    return true;
};

const shouldLookForMoreFaces = (action, state) => {
    if (action.cursors.nextPage != null) return true;

    return isOnFirstPartOfSelectionSorting(state);
};

const needToSwitchIsFilteringOnSelectedFaces = (action, state) => {
    if (action.cursors.nextPage != null) return false;

    return isOnFirstPartOfSelectionSorting(state);
};

const staticInventoryReducer = (state = createInitialState().staticInventory, action) => {
    const newState = { ...state };
    switch (action.type) {
        case RETRIEVE_STATIC_INVENTORY: {
            let { gridRows } = newState;
            const allItems = { ...newState.inventoryItems };

            const facesWithAvailability = [];

            action.faces.forEach(datum => {
                const faceWithAvailability = { ...datum };
                const availabilityForFace = action.availabilities.find(x => x.faceId === datum.id);

                const availability =
                    availabilityForFace.availability &&
                    availabilityForFace.availability.replace(' ', '_').toLowerCase();
                faceWithAvailability.availability = AVAILABILITY_STATUS(availability);

                facesWithAvailability.push(faceWithAvailability);
            });

            const filteredFaces = applyAvailabilityFilter(facesWithAvailability, newState.filters);
            filteredFaces.forEach(filteredFace => {
                allItems[filteredFace.id] = filteredFace;
            });
            const newGridRows = filteredFaces.map(f => f.id);
            gridRows = [...new Set(gridRows.concat(newGridRows))];

            newState.lastSearchedIndex = action.stopIndex;
            if (shouldLookForMoreFaces(action, newState)) {
                const stepSize = action.stopIndex - action.startIndex + 1;
                newState.matchingTotal = gridRows.length + stepSize;
            } else {
                newState.matchingTotal = gridRows.length;
            }

            if (needToSwitchIsFilteringOnSelectedFaces(action, newState)) {
                newState.isFilteringOnSelectedFaces = !newState.isFilteringOnSelectedFaces;
                newState.lastSearchedIndex = -1;
            }

            newState.gridRows = gridRows;
            newState.inventoryItems = allItems;

            break;
        }
        case BEGIN_INVENTORY_LOADING:
            newState.isInventoryDataLoading = true;
            break;
        case END_INVENTORY_LOADING:
            newState.isInventoryDataLoading = false;
            break;
        case CLEAR_INVENTORY: {
            newState.matchingTotal = undefined;
            newState.unfilteredCount = undefined;
            newState.gridRows = [];
            newState.inventoryItems = {};
            newState.lastSearchedIndex = -1;
            resetSelectionSort(newState);
            break;
        }
        case ADD_AVAILABILITY_STATUS_FILTER: {
            const newFilter = createOptionFilter(action, 'availabilityStatus');
            newState.filters = addFilter({
                newFilter,
                filtersList: newState.filters,
                equality: f => f.type === 'availabilityStatus' && f.id === newFilter.id,
            });
            break;
        }
        case ADD_FACE_NAME_FILTER: {
            const newFilter = createStringFilter(action, 'name');
            newState.filters = addFilter({
                newFilter,
                filtersList: newState.filters,
                equality: f => f.type === 'name' && f.name === newFilter.name,
            });
            break;
        }
        case ADD_FORMAT_ID_FILTER: {
            const newFilter = createOptionFilter(action, 'formatId');
            newState.filters = addFilter({
                newFilter,
                filtersList: newState.filters,
                equality: f => f.type === 'formatId' && f.id === newFilter.id,
            });
            break;
        }
        case ADD_SITE_NAME_FILTER: {
            const newFilter = createStringFilter(action, 'siteName');
            newState.filters = addFilter({
                newFilter,
                filtersList: newState.filters,
                equality: f => f.type === 'siteName' && f.name === newFilter.name,
            });
            break;
        }
        case DELETE_STATIC_FILTER: {
            const targetIndex = newState.filters.indexOf(
                newState.filters.find(
                    ({ type, name }) => type === action.payload.type && name === action.payload.name
                )
            );
            newState.filters = newState.filters.filter(
                el => newState.filters.indexOf(el) !== targetIndex
            );
            break;
        }
        case DELETE_ALL_STATIC_FILTERS: {
            newState.filters = [];
            break;
        }
        case UPDATE_SALES_PERIOD_FILTER: {
            newState.salesPeriod = {
                startDate: formatDate(action.payload.startDate),
                endDate: formatDate(action.payload.endDate),
                durationInDays: action.payload.durationInDays,
            };
            break;
        }
        case UPDATE_STATIC_SORT_BY:
            newState.sortBy = action.payload.map(entry => {
                return {
                    ...entry,
                };
            });

            resetSelectionSort(newState);
            break;
        case SELECT_STATIC_INVENTORY_ITEMS:
            newState.selectedInventoryItemIds = selectInventoryItems(
                state.selectedInventoryItemIds,
                action.payload.selectedIds
            );
            break;
        case UNSELECT_STATIC_INVENTORY_ITEMS:
            newState.selectedInventoryItemIds = unselectInventoryItems(
                state.selectedInventoryItemIds,
                action.payload.unselectedIds
            );
            break;
        default:
            break;
    }
    return newState;
};

export default staticInventoryReducer;
