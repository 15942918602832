import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Image extends PureComponent {
    render() {
        const { src, alt, className } = this.props;
        return (
            <div>
                <img src={src} alt={alt} className={className} />
            </div>
        );
    }
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
};

Image.defaultProps = {
    className: undefined,
};

export default Image;
