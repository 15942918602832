export const keyCodes = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    DELETE: 46,
    ZERO: 48,
    ONE: 49,
    TWO: 50,
    THREE: 51,
    FOUR: 52,
    FIVE: 53,
    SIX: 54,
    SEVEN: 55,
    EIGHT: 56,
    NINE: 57,
    A: 65,
    C: 67,
    V: 86,
    ZERO_NUM_PAD: 96,
    ONE_NUM_PAD: 97,
    TWO_NUM_PAD: 98,
    THREE_NUM_PAD: 99,
    FOUR_NUM_PAD: 100,
    FIVE_NUM_PAD: 101,
    SIX_NUM_PAD: 102,
    SEVEN_NUM_PAD: 103,
    EIGHT_NUM_PAD: 104,
    NINE_NUM_PAD: 105,
    DASH_NUM_PAD: 109,
    COMMA_DOT_NUM_PAD: 110,
    COMMA: 188,
    DASH: 189,
    DOT: 190,
    SPACE: 32,
};

export const keyCodesNumbers = [
    keyCodes.ZERO,
    keyCodes.ONE,
    keyCodes.TWO,
    keyCodes.THREE,
    keyCodes.FOUR,
    keyCodes.FIVE,
    keyCodes.SIX,
    keyCodes.SEVEN,
    keyCodes.EIGHT,
    keyCodes.NINE,
    keyCodes.ZERO_NUM_PAD,
    keyCodes.ONE_NUM_PAD,
    keyCodes.TWO_NUM_PAD,
    keyCodes.THREE_NUM_PAD,
    keyCodes.FOUR_NUM_PAD,
    keyCodes.FIVE_NUM_PAD,
    keyCodes.SIX_NUM_PAD,
    keyCodes.SEVEN_NUM_PAD,
    keyCodes.EIGHT_NUM_PAD,
    keyCodes.NINE_NUM_PAD,
];

export const keyCodesArrows = [keyCodes.LEFT, keyCodes.UP, keyCodes.RIGHT, keyCodes.DOWN];

export const MENU_PAGES = {
    DASHBOARD: {
        identifier: 'DASHBOARD',
        labelKey: 'common:LblMenuDashboard',
    },
    PROPOSALS: {
        identifier: 'PROPOSALS',
        labelKey: 'common:LblMenuProposals',
    },
    PACKAGES: {
        identifier: 'PACKAGES',
        labelKey: 'common:LblMenuPackages',
    },
    INVENTORY_DIGITAL: {
        identifier: 'INVENTORY_DIGITAL',
        labelKey: 'common:LblMenuDigital',
    },
    INVENTORY_STATIC: {
        identifier: 'INVENTORY_STATIC',
        labelKey: 'common:LblMenuStatic',
    },
    INVENTORY: {
        identifier: 'INVENTORY',
        labelKey: 'common:LblMenuInventory',
    },
    REACT_INVENTORY_DIGITAL: {
        identifier: 'REACT_INVENTORY_DIGITAL',
        labelKey: 'common:LblMenuDigital',
    },
    REACT_INVENTORY_STATIC: {
        identifier: 'REACT_INVENTORY_STATIC',
        labelKey: 'common:LblMenuInventoryBeta',
    },
    SETTINGS: {
        identifier: 'SETTINGS',
        labelKey: 'common:LblMenuSettings',
    },
};

export const PROPOSAL_GOAL_TYPES = {
    impressions: {
        type: 'goal_impressions',
        labelKey: 'ProposalGoalImpressions',
    },
    budget: {
        type: 'goal_budget',
        labelKey: 'ProposalGoalBudget',
    },
    plays: {
        type: 'goal_repetitions',
        labelKey: 'ProposalGoalPlays',
    },
};

export const LABEL_BY_PROPOSAL_GOAL_TYPE = {
    goal_impressions: 'ProposalGoalImpressions',
    goal_budget: 'ProposalGoalBudget',
    goal_repetitions: 'ProposalGoalPlays',
};

export const TYPES_OF_BUY = Object.assign(
    type => Object.values(TYPES_OF_BUY).find(value => value.type === type) || TYPES_OF_BUY[type],
    {
        frequency: {
            type: 'ppl',
            labelKey: 'inventory:LblFrequency',
            defaultValues: { bs_saturation: 1, saturation: 1 },
            hasErrors: () => false,
            isAmendable: true,
        },
        sov: {
            type: 'sov',
            labelKey: 'inventory:LblShareOfVoice',
            defaultValues: { sov: 16.67 },
            hasErrors: () => false,
            isAmendable: true,
        },
        takeover: {
            type: 'takeover',
            labelKey: 'inventory:LblTakeover',
            defaultValues: { sov: 100 },
            hasErrors: () => false,
            isAmendable: true,
        },
        impressions: {
            type: 'goal_impressions',
            labelKey: 'inventory:LblImpressionsTypeOfBuy',
            defaultValues: { impressions: null, budget: null, cpm: null },
            hasErrors: values => {
                if (!values.impressions) {
                    return { primaryImpressionsEmpty: true };
                }
                return false;
            },
            isAmendable: true,
        },
        budget: {
            type: 'goal_budget',
            labelKey: 'inventory:LblBudgetTypeOfBuy',
            defaultValues: { impressions: null, budget: null, cpm: null },
            hasErrors: values => {
                if (!values.budget) {
                    return { primaryBudgetEmpty: true };
                }
                return false;
            },
            isAmendable: true,
        },
        play: {
            type: 'goal_repetitions',
            labelKey: 'inventory:LblPlayTypeOfBuy',
            defaultValues: { repetitions: null },
            hasErrors: values => {
                if (!values.repetitions) {
                    return { primaryPlayGoalEmpty: true };
                }
                return false;
            },
            isAmendable: true,
        },
        avgsov: {
            type: 'average_sov',
            labelKey: 'inventory:LblShareOfVoice',
            defaultValues: { sov: 16.67, distribution: 'day' },
            hasErrors: () => false,
            isAmendable: true,
        },
        matrix: {
            type: 'matrix',
            labelKey: 'inventory:LblMatrixTypeOfBuy',
            isAmendable: false,
            hasErrors: () => false,
            defaultValues: { saturation: 1 },
        },
    }
);

export const PERCENTAGE_INTERVAL = {
    MIN: 0.0001,
    MAX: 100,
};

export const REFRESH_TOKEN_INTERVAL = 270000;

export const CONTAINER_SIZE = {
    MEDIUM: 'medium',
    LARGE: 'large',
};
