import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import { applyCustomStyle, unitSize } from '../../../utils/style.utils';
import s from './buttonSpinner.css';

class ButtonSpinner extends PureComponent {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this._debouncedClick = debounce(500, props.onClick);
        this.buttonRef = React.createRef();
    }

    componentDidMount() {
        const { width, customStyle } = this.props;
        this.buttonRef.current.style.width = unitSize(width);
        if (Object.keys(customStyle).length > 0) {
            applyCustomStyle(customStyle, this.buttonRef);
        }
    }

    handleClick(event) {
        const { onClick, disabled } = this.props;
        if (onClick && !disabled && this._debouncedClick) {
            event.persist(); // to take care of React event pooling https://reactjs.org/docs/events.html#event-pooling
            this._debouncedClick(event);
        }
    }

    renderContentButton() {
        const { loadingText } = this.props;
        return (
            <span>
                {loadingText}
                <span className={s.spinner}>
                    <span className={s.bounce1} />
                    <span className={s.bounce2} />
                    <span className={s.bounce3} />
                </span>
            </span>
        );
    }

    render() {
        const { className, dataTest, disabled, label, isLoading } = this.props;
        let classes = s[className];
        if (disabled === true) {
            classes = `${classes} ${s[`${className}-disabled`]}`;
        }
        return (
            <button
                type="button"
                ref={this.buttonRef}
                className={classes}
                onClick={this.handleClick}
                disabled={disabled}
                data-test={dataTest}
            >
                {isLoading ? this.renderContentButton() : label}
            </button>
        );
    }
}

ButtonSpinner.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    loadingText: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
    customStyle: PropTypes.shape({}),
    dataTest: PropTypes.string,
};

ButtonSpinner.defaultProps = {
    className: 'primary',
    label: undefined,
    disabled: false,
    onClick: null,
    customStyle: {},
    dataTest: undefined,
};

export default ButtonSpinner;
