import variables from '../css/variables';

export const DEFAULT_THEME = {
    pillColors: {
        criteria_and: variables.criteriaFilterPill,
        criteria_not: variables.criteriaFilterPillNot,
        keyword: variables.keywordFilterPill,
        custom_screens: variables.customScreensFilterPill,
        contextual_targeting: variables.contextualFilterPill,
    },
};
export const REBRAND_THEME = {
    pillColors: {
        criteria_and: variables.inventoryFilterCheckboxUnchecked,
        criteria_not: variables.criteriaFilterPillNot,
        keyword: variables.periwinkleGray,
        custom_screens: variables.white,
        contextual_targeting: variables.broadsignNavDropdownHover,
    },
};
export const THEME_ENUMS = {
    DEFAULT_THEME: 'css/theme/default',
    REBRAND_THEME: 'css/theme/rebrand',
};
