const xxsmallPadding = '4px';
const xsmallPadding = '8px';
const smallPadding = '12px';
const standardPadding = '16px';
const largePadding = '24px';
const xlargePadding = '28px';

const xxSmallMargin = '4px';
const xsmallMargin = '8px';
const smallMargin = '12px';
const standardMargin = '16px';
const largeMargin = '24px';
const xlargeMargin = '28px';

const settingsPanelWidth = '292px';
const maxWidthProposalItemDetailValue = '168px';

const sizes = {
    xxsmallPadding,
    xsmallPadding,
    smallPadding,
    standardPadding,
    largePadding,
    xlargePadding,

    xxSmallMargin,
    xsmallMargin,
    smallMargin,
    standardMargin,
    largeMargin,
    xlargeMargin,

    formFieldHeight: '42px',
    defaultInputHeight: '42px',
    defaultBorderRadius: '4px',
    settingsPanelWidth,
    fillRatePopupHeight: '360px',
    fillRatePopupWidth: `calc(100% -  (2 * ${standardPadding}) - ${settingsPanelWidth})`,
    fillRatePopupInLightboxWidth: `calc(100% -  (2 * ${standardPadding}) - (2 * ${largePadding}) - ${settingsPanelWidth})`,
    maxWidthProposalItemDetailValue,
};

module.exports = sizes;
