export const unitSize = (value, unit = 'px') => `${value}${unit}`;

export const applyCustomStyle = (styles, ref) => {
    if (ref && ref.current && typeof styles === 'object') {
        const myRef = ref;
        Object.entries(styles).forEach(([key, value]) => {
            myRef.current.style[key] = value;
        });
    }
};

export const SIZES = {
    MIN_WINDOW_WIDTH: 1366,
    MIN_WINDOW_HEIGHT: 768,
};
