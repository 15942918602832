const createInitialState = () => {
    return {
        global: {
            activePage: '',
            authentication: {
                loading: true,
                success: false,
            },
            user: {},
            domain: {},
            locale: 'en',
        },
        staticInventory: {
            inventoryItems: {},
            gridRows: [],
            selectedInventoryItemIds: {},
            filters: [],
            inventoryType: 'static',
            sortBy: [],
            salesPeriod: {
                startDate: '1970-01-01',
                endDate: '1970-01-01',
                durationInDays: 1,
            },
            lastSearchedIndex: -1,
            isFilteringOnSelectedFaces: false,
        },
    };
};

export default createInitialState;
