import createInitialState from './initialState';
import * as actionTypes from '../actions/actionTypes';

const globalReducer = (state = createInitialState().global, action) => {
    const newState = { ...state };
    switch (action.type) {
        case actionTypes.USER_DATA_LOADED: {
            newState.authentication = { ...newState.authentication, loading: false };
            break;
        }
        case actionTypes.SET_ACTIVE_PAGE: {
            newState.activePage = action.payload;
            break;
        }
        case actionTypes.SET_USER_DATA: {
            const { user } = action.data;
            newState.user = (({ domain, ...others }) => ({ ...others }))(user);
            newState.authentication = { ...newState.authentication, success: true };
            break;
        }
        case actionTypes.LOG_ERROR: {
            const { type, id } = action.payload;
            newState.errors = [...newState.errors, { type, id }];
            break;
        }
        case actionTypes.ACKNOWLEDGE_ERROR: {
            const { id } = action.payload;
            newState.errors = newState.errors.filter(error => error.id !== id);
            break;
        }
        case actionTypes.SET_DOMAIN_SETTINGS: {
            newState.domain = { ...newState.domain, ...action.payload };
            break;
        }
        case actionTypes.SET_THIRD_PARTY_CPM_SETTINGS: {
            newState.thirdPartyCPMSettings = action.payload;
            break;
        }
        case actionTypes.SET_THIRD_PARTY_CPM_SETTINGS_ERROR: {
            newState.thirdPartyCPMSettings = {
                ...newState.thirdPartyCPMSettings,
                error: action.payload.error,
            };
            break;
        }
        case actionTypes.SET_USER_SETTINGS: {
            newState.user.settings = action.payload;
            break;
        }
        default: {
            break;
        }
    }
    return newState;
};

export default globalReducer;
