export const ADD_AVAILABILITY_STATUS_FILTER = 'ADD_AVAILABILITY_STATUS_FILTER';
export const ADD_CRITERION_FILTER = 'ADD_CRITERION_FILTER';
export const ADD_CUSTOM_SCREEN_FILTER = 'ADD_CUSTOM_SCREEN_FILTER';
export const ADD_FACE_NAME_FILTER = 'ADD_FACE_NAME_FILTER';
export const ADD_FORMAT_ID_FILTER = 'ADD_FORMAT_ID_FILTER';
export const ADD_SCREEN_FORMAT_FILTER = 'ADD_SCREEN_FORMAT_FILTER';
export const ADD_SCREEN_NAME_FILTER = 'ADD_SCREEN_NAME_FILTER';
export const ADD_SITE_NAME_FILTER = 'ADD_SITE_NAME_FILTER';
export const UPDATE_SALES_PERIOD_FILTER = 'UPDATE_SALES_PERIOD_FILTER';
export const USER_DATA_LOADED = 'USER_DATA_LOADED';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const RETRIEVE_INVENTORY = 'RETRIEVE_INVENTORY';
export const BEGIN_INVENTORY_LOADING = 'BEGIN_INVENTORY_LOADING';
export const END_INVENTORY_LOADING = 'END_INVENTORY_LOADING';
export const RETRIEVE_STATIC_INVENTORY = 'RETRIEVE_STATIC_INVENTORY';
export const UPDATE_SORT_BY = 'UPDATE_SORT_BY';
export const UPDATE_STATIC_SORT_BY = 'UPDATE_STATIC_SORT_BY';
export const SET_FLIGHT_DURATION = 'SET_FLIGHT_DURATION';
export const CLEAR_CART_ERROR = 'CLEAR_CART_ERROR';
export const CLEAR_INVENTORY = 'CLEAR_INVENTORY';
export const SELECT_ALL_INVENTORY_ITEMS = 'SELECT_ALL_INVENTORY_ITEMS';
export const SELECT_INVENTORY_ITEMS = 'SELECT_INVENTORY_ITEMS';
export const UNSELECT_ALL_INVENTORY_ITEMS = 'UNSELECT_ALL_INVENTORY_ITEMS';
export const UNSELECT_INVENTORY_ITEMS = 'UNSELECT_INVENTORY_ITEMS';
export const SELECT_STATIC_INVENTORY_ITEMS = 'SELECT_STATIC_INVENTORY_ITEMS';
export const UNSELECT_STATIC_INVENTORY_ITEMS = 'UNSELECT_STATIC_INVENTORY_ITEMS';
export const SET_CART_DATA = 'SET_CART_DATA';
export const BEGIN_SET_CART_DATA = 'BEGIN_SET_CART_DATA';
export const END_SET_CART_DATA = 'END_SET_CART_DATA';
export const GET_SUMMARY_DATA = 'GET_SUMMARY_DATA';
export const RESET_SUMMARY_DATA = 'RESET_SUMMARY_DATA';
export const GET_SUMMARY_DATA_ERROR = 'GET_SUMMARY_DATA_ERROR';
export const ROLLBACK_CART = 'ROLLBACK_CART';
export const SET_ACTIVE_TYPE_OF_BUY = 'SET_ACTIVE_TYPE_OF_BUY';
export const SET_TYPE_OF_BUY_VALUES = 'SET_TYPE_OF_BUY_VALUES';
export const SET_TYPE_OF_BUY_ERROR = 'SET_TYPE_OF_BUY_ERROR';
export const CLEAR_TYPE_OF_BUY_ERRORS = 'CLEAR_TYPE_OF_BUY_ERRORS';
export const DELETE_FILTER = 'DELETE_FILTER';
export const DELETE_STATIC_FILTER = 'DELETE_STATIC_FILTER';
export const DELETE_ALL_FILTERS = 'DELETE_ALL_FILTERS';
export const DELETE_ALL_STATIC_FILTERS = 'DELETE_ALL_STATIC_FILTERS';
export const SET_DEPLOYED_ONLY = 'SET_DEPLOYED_ONLY';
export const CLEAR_SELECTED_INVENTORY_ITEMS = 'CLEAR_SELECTED_INVENTORY_ITEMS';
export const SET_AVAILABLE_ONLY = 'SET_AVAILABLE_ONLY';
export const CREATE_PROPOSAL = 'CREATE_PROPOSAL';
export const APPLY_AD_SETTINGS = 'APPLY_AD_SETTINGS';
export const SET_OVERLAPS = 'SET_OVERLAPS';
export const CLEAR_OVERLAPS = 'CLEAR_OVERLAPS';
export const SET_PROPOSAL_BUILDER_FIELDS = 'SET_PROPOSAL_BUILDER_FIELDS';
export const SET_PROPOSAL_DATA = 'SET_PROPOSAL_DATA';
export const SET_LINE_ITEM_DATA = 'SET_LINE_ITEM_DATA';
export const SET_LINE_ITEMS = 'SET_LINE_ITEMS';
export const SET_PROPOSAL_GOALS = 'SET_PROPOSAL_GOALS';
export const SET_PROPOSAL_GOAL_DATA = 'SET_PROPOSAL_GOAL_DATA';
export const OPEN_PROPOSAL_GOAL_MODAL = 'OPEN_PROPOSAL_GOAL_MODAL';
export const CLOSE_PROPOSAL_GOAL_MODAL = 'CLOSE_PROPOSAL_GOAL_MODAL';
export const SET_PROPOSAL_SUMMARY_DATA = 'SET_PROPOSAL_SUMMARY_DATA';
export const SET_PROPOSAL_ITEM_DATA = 'SET_PROPOSAL_ITEM_DATA';
export const SET_PROPOSAL_ITEM_NAME = 'SET_PROPOSAL_ITEM_NAME';
export const OPEN_PROPOSAL_ITEM_LIGHTBOX = 'OPEN_PROPOSAL_ITEM_LIGHTBOX';
export const CLOSE_PROPOSAL_ITEM_LIGHTBOX = 'CLOSE_PROPOSAL_ITEM_LIGHTBOX';
export const SET_WRITE_LEVEL = 'SET_WRITE_LEVEL';
export const LOG_ERROR = 'LOG_ERROR';
export const ACKNOWLEDGE_ERROR = 'ACKNOWLEDGE_ERROR';
export const SET_INVENTORY_TYPE = 'SET_INVENTORY_TYPE';
export const SET_LIGHTBOX_ERROR = 'SET_LIGHTBOX_ERROR';
export const CLEAR_LIGHTBOX_ERROR = 'CLEAR_LIGHTBOX_ERROR';
export const SAVE_PROPOSAL_ITEM_BEGIN = 'SAVE_PROPOSAL_ITEM_BEGIN';
export const SAVE_PROPOSAL_ITEM_END = 'SAVE_PROPOSAL_ITEM_END';
export const UPDATE_SUMMARY_COUNT = 'UPDATE_SUMMARY_COUNT';
export const SET_MATCHING_COUNT_UNIT = 'SET_MATCHING_COUNT_UNIT';
export const SET_AD_SETTINGS_DIRTY = 'SET_AD_SETTINGS_DIRTY';
export const SET_DOMAIN_SETTINGS = 'SET_DOMAIN_SETTINGS';
export const ADD_PROPOSAL_ITEM_INLINE_ACTION = 'ADD_PROPOSAL_ITEM_INLINE_ACTION';
export const REMOVE_PROPOSAL_ITEM_INLINE_ACTION = 'REMOVE_PROPOSAL_ITEM_INLINE_ACTION';
export const REMOVE_PROPOSAL_GOAL_ACTION = 'REMOVE_PROPOSAL_GOAL_ACTION';
export const SET_PROPOSAL_GOAL_INLINE_ACTION = 'SET_PROPOSAL_GOAL_INLINE_ACTION';
export const BEGIN_THIRD_PARTY_CPM_LOADING = 'BEGIN_THIRD_PARTY_CPM_LOADING';
export const END_THIRD_PARTY_CPM_LOADING = 'END_THIRD_PARTY_CPM_LOADING';
export const GET_THIRD_PARTY_CPM_DATA = 'GET_THIRD_PARTY_CPM_DATA';
export const GET_THIRD_PARTY_CPM_ERROR = 'GET_THIRD_PARTY_CPM_ERROR';
export const RESET_THIRD_PARTY_CPM_DATA = 'RESET_THIRD_PARTY_CPM_DATA';
export const SET_THIRD_PARTY_CPM_SETTINGS = 'SET_THIRD_PARTY_CPM_SETTINGS';
export const SET_THIRD_PARTY_CPM_SETTINGS_ERROR = 'SET_THIRD_PARTY_CPM_SETTINGS_ERROR';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const ADD_CONTEXTUAL_TARGETING_FILTER = 'ADD_CONTEXTUAL_TARGETING_FILTER';
export const GET_CREATIVE_MANAGEMENT_STATUS_BEGIN = 'GET_CREATIVE_MANAGEMENT_STATUS_BEGIN';
export const GET_CREATIVE_MANAGEMENT_STATUS_END = 'GET_CREATIVE_MANAGEMENT_STATUS_END';
export const SET_CREATIVE_MANAGEMENT_STATUS = 'SET_CREATIVE_MANAGEMENT_STATUS';
export const SET_CREATIVE_MANAGEMENT_STATUS_ERROR = 'SET_CREATIVE_MANAGEMENT_STATUS_ERROR';
export const SET_CREATIVE_MANAGEMENT_STATUS_PERMISSIONS_ERROR =
    'SET_CREATIVE_MANAGEMENT_STATUS_PERMISSIONS_ERROR';
export const SET_JWT = 'SET_JWT';
export const PROMOTED_CAMPAIGN_BEGIN = 'PROMOTED_CAMPAIGN_BEGIN';
export const PROMOTED_CAMPAIGN_END = 'PROMOTED_CAMPAIGN_END';
export const PROMOTED_CAMPAIGN_SUCCESS = 'PROMOTED_CAMPAIGN_SUCCESS';
export const PROMOTED_CAMPAIGN_ERROR = 'PROMOTED_CAMPAIGN_ERROR';
