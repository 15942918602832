import importedTranslations from './i18n.en';

export default {
    ...importedTranslations,
    AvailabilityCheckFail:
        'The availability check for the campaign could not be completed due to a problem with the request. The campaign cannot be {{type}}.',
    ResourceProposal: 'campaign',
    BtnBuildNewProposal: 'Build new campaign',
    BtnLightboxAddToProposal: 'Add to Campaign',
    BtnLightboxUpdateProposal: 'Update Line Item',
    BtnLightboxAmendProposal: 'Amend Line Item',
    LblItemExportDisabled: 'Selection modified. Update line item before exporting',
    PlaceholderProposal: 'New campaign',
    PlaceholderProposalName: 'Enter Campaign Name',
    ProposalUniqueName: 'New Campaign',
    LblDynamicSelectionDescription:
        '{{sign}} in this section vary based on saved filters, but will be fixed when this package is added to a campaign',
    LblFixedSelectionDescription:
        '{{sign}} in this section will always be selected when this package is added to a campaign',
    LblBtnReleaseTooltip: 'Releases campaign from hold',
    LblBtnHoldTooltip: 'Check availability and hold campaign',
    LblHoldExpiryMessage: 'Your campaign will be held until',
    LblBtnRenewTooltip: 'Renews hold on campaign',
    PROPOSAL_SALESFORCE_HOLD_LOCK_ENABLED: 'Campaign cannot be held because hold lock is enabled',
    PROPOSAL_SALESFORCE_BOOK_LOCK_ENABLED: 'Campaign cannot be booked because book lock is enabled',
    ToastProposalHoldError: 'Failed to hold a campaign. Please try again later',
    ToastProposalReleaseError: 'Failed to release hold on a campaign. Please try again later',
    ToastProposalHoldRenewError: 'Failed to renew hold on a campaign. Please try again later',
    ToastProposalRequestHoldError: 'Failed to request hold on a campaign. Please try again later',
    ToastProposalRequestHoldErrorNotEnforced:
        'The hold request is not enforced, you do not have permission to request a hold on a campaign.',
    ToastProposalRequestHoldErrorAlreadyRequested:
        'A hold has already been requested on this campaign. Please wait until it has been either approved or rejected before trying again.',
    ToastProposalRenewRequestHoldError:
        'Failed to request renew hold on a campaign. Please try again later',
    ToastProposalRequestHoldCannotBeDeleted: 'Campaign requested for hold cannot be deleted',
    ToastProposalItemRequestHoldCannotBeDeleted:
        'Line items in a campaign requested for hold cannot be deleted',
    ToastProposalRequestHoldBookNotAllowed: 'You cannot book a campaign requested for hold',
    ToastProposalItemRequestHoldCloneNotAllowed:
        'You cannot clone a line item in a campaign requested for hold',
    ToastAddProposalItemHoldRequestNotAllowed:
        'You cannot add line items to a campaign requested for hold',
    ToastHoldAlreadyRequestedAndApproved:
        'A hold has already been requested and approved on this campaign. Please wait until campaign has been fully held.',
    LblLoadingMessagePuttingOnHold: 'Putting hold on campaign...',
    LblLoadingMessageRenewingHold: 'Renewing hold on campaign...',
    LblLoadingMessageCloneProposalItem: 'Cloning line item...',
    LblLoadingMessageReleaseProposalItem: 'Releasing line item from hold...',
    ToastLineItemCloneError: 'Failed to clone a line item.',
    ToastLineItemReleaseError: 'Failed to release hold on a line item.',
    ToastItemCancelError: 'Failed to cancel a line item.',
    PROPOSAL_ITEM_NOT_CANCELLABLE: 'Line item is not cancellable',
    LblBookGoBackEditTooltip: 'Go back editing the campaign',
    BookingImpossible: 'It is impossible to book a campaign without any screens available',
    LblLoadingMessagePreparingDownload: 'Preparing campaign download...',
    LblLoadingMessagePreSaving: 'Pre saving the campaign...',
    LblLoadingMessageBookingProposal: 'Booking the campaign...',
    LblLoadingMessageBookingProposalSubmitted: 'You have submitted the campaign for booking.',
    LblLoadingMessageAutoSaving: 'Auto saving campaign...',
    LblLoadingMessageSavingProposal: 'Saving the campaign...',
    LblSummaryPrice: 'Campaign Price',
    ToastFailedToUpdateProposalItem: 'Failed to update line item.',
    ToastMessageProposalSaved: 'Your campaign was successfully saved!',
    ToastMessageProposalSyncStarted: 'Your campaign is syncing',
    ToastMessageProposalBooked: 'Campaign was successfully booked.',
    ToastProposalBookFailed: 'Failed to book a campaign. Please try again.',
    ALL_SCREENS_UNAVAILABLE:
        'This campaign does not contain available screens and cannot be booked',
    ToastAmendError: 'Failed to amend line item. Please try again.',
    ToastAmendSuccess: 'Line item has been successfully amended',
    ToastProposalItemSaveError: 'Failed to add a line item. Please try again later',
    ToastProposalItemFetchError: 'Failed to fetch a line item. Please try again later',
    ToastProposalItemUpdateError: 'Failed to update a line item. Please try again later',
    ToastProposalLockedAccess:
        'This campaign is managed and editing was locked by your external systems business rules',
    ToastProposalAlreadyBooked: 'Campaign "{{name}}" has already been booked',
    PROPOSAL_NAME_REQUIRED:
        'Campaign name is required. Provide a campaign name before saving the campaign',
    PROPOSAL_ITEM_NAME: 'Line item name',
    PROPOSAL_ITEM_NAME_REQUIRED: 'Please provide a line item name',
    INVALID_PROPOSAL_ITEM_IDS: 'It is impossible to book this campaign',
    INVALID_START_DATE: 'Line item has to have start date on {{dow}}',
    PROPOSAL_ITEM_UNBOOKABLE: 'It is impossible to book this campaign',
    PROPOSAL_NOT_FOUND: 'Failed to find and book this campaign',
    BOOKING_FAIL: 'It is impossible to book a campaign at the moment',
    PROPOSAL_ITEM_ALREADY_BOOKED: 'This campaign is already booked',
    PROPOSAL_ALREADY_BOOKED: 'This campaign is already booked',
    PROPOSAL_NO_PREMISSION_REMOVE_HOLD: "User can't remove hold on selected campaign",
    PROPOSAL_NO_PERMISSION_HOLD: "User can't set hold on selected campaign",
    PROPOSAL_NO_PERMISSION_RENEW: "User can't renew hold on selected campaign",
    PROPOSAL_NO_PERMISSION_BOOK: "User can't book selected campaign",
    PROPOSAL_NO_PERMISSION_DELETE: "User can't delete selected campaign",
    PROPOSAL_NO_PERMISSION_CANCEL: "User can't cancel selected campaign",
    PROPOSAL_NO_PERMISSION_EDIT: "User can't edit selected campaign",
    PROPOSAL_ITEM_NOT_EDITABLE:
        'Line item cannot be updated because of its status or other restrictions',
    Proposal: 'Campaign',
    ProposalHeader: 'Build a Campaign',
    ProposalTagline: 'Enter details of your campaign below.',
    SelectInventoryTaglineFeatureFlagged:
        'A line item can be built with either a dynamic or a fixed selection of screens',
    ProposalItems: 'line items',
    CONTRACT_ID_MANDATORY:
        'Contract ID is required. Provide a contract ID before submitting the campaign',
    CONTRACT_ID_MANDATORY_SAVING:
        'Contract ID is required. Provide a contract ID before saving the campaign',
    PROPOSAL_NOT_IN_PLAN:
        'Campaign at line {{lineNumber}} is not included in the plan\t(Error message: {{errorMessage}})',
    FACE_WILL_BE_ADDED:
        'Face "{{faceName}}" (ID: {{faceId}}) at line {{line}} will be added from Line Item "{{proposalItemName}}" (ID: {{proposalItemId}})',
    FACE_WILL_BE_REMOVED:
        'Face "{{faceId}}" will be removed from Line Item "{{proposalItemName}}" (ID: {{proposalItemId}})',
    PROPOSAL_ITEM_WILL_BE_CANCELLED:
        'Line Item "{{proposalItemName}}" (ID: {{proposalItemId}}) at line {{line}} has no more associated faces and will be cancelled',
    PROPOSAL_ITEM_NOT_FOUND:
        'Line Item "{{proposalItemName}}" (ID: {{proposalItemId}}) associated with face {{faceName}} at line (ID: {{faceId}}) cannot be found',
    INVALID_PROPOSAL: 'Could not find campaign',
    PROPOSAL_UPDATE_NOT_ALLOWED: 'Failed to save campaign. Please try again.',
    INVALID_PACKAGE_VALUES:
        '*<span class="st-invalid">Highlighted</span> properties do not match your campaign info. You might want to remove the associated package or adjust your campaign.',
    INVALID_PROPOSAL_STATUS: 'Campaign status is invalid',
    ErrorCouldNotDownloadPDF: 'Could not generate the campaign PDF file',
    ProposalCancelTooltipLbl: 'Delete campaign permanently',
    ToastProposalItemReserved:
        'Held line items are not editable. Release campaign from hold to edit',
    ToastProposalDeleteForbidden:
        "Another user changed this campaign's status. You are now unable to delete it.",
    TakeoverBookImpossible:
        'You cannot book because you have a line item blocked by another takeover.',
    LblYourProposal: 'Your campaign...',
    LblProposalLineItem: 'Line Item',
    SaveFeedback: { isSavingText: 'Saving changes...', lastSavedText: 'Last saved:' },
    CancelMessage: 'You are about to cancel this line item. Are you sure you want to proceed?',
    LoadingProposalItems: 'Fetching line items',
    UpdatingProposalItems: 'Updating line items',
    UpdatingProposalItem: 'Updating line item',
    ToastErrorProposalItemFetch: 'Failed to fetch line items',
};
