// eslint-disable-next-line import/prefer-default-export
export const AVAILABILITY_STATUS = Object.assign(
    v =>
        Object.values(AVAILABILITY_STATUS).find(value => v && value === v.toLowerCase()) ||
        AVAILABILITY_STATUS.NOT_AVAILABLE,
    {
        AVAILABLE: 'available',
        PARTIALLY_AVAILABLE: 'partially_available',
        NOT_AVAILABLE: 'not_available',
    }
);
