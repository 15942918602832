import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import s from './input.css';
import variables from '../../../css/variables';
import { unitSize } from '../../../utils/style.utils';
import Tooltip from '../Tooltip/Tooltip';

class Input extends Component {
    static applyCustomStyle(styles, ref) {
        const myRef = ref;
        Object.entries(styles).forEach(([key, value]) => {
            myRef.current.style[key] = value;
        });
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);

        this.inputRef = React.createRef();
        this.labelRef = React.createRef();
    }

    componentDidMount() {
        const {
            borderColor,
            width,
            height,
            paddingRight,
            customStyle,
            labelStyle,
            setFocusOnSpinClick,
        } = this.props;
        this.inputRef.current.style.border = `solid 1px ${borderColor}`;
        this.inputRef.current.style.width = unitSize(width);
        this.inputRef.current.style.height = unitSize(height);
        this.inputRef.current.style.paddingRight = unitSize(paddingRight);
        if (Object.keys(customStyle).length > 0) {
            Input.applyCustomStyle(customStyle, this.inputRef);
        }
        if (Object.keys(labelStyle).length > 0) {
            Input.applyCustomStyle(labelStyle, this.labelRef);
        }

        if (setFocusOnSpinClick) {
            setFocusOnSpinClick(this.handleFocus);
        }
    }

    handleChange(event) {
        const { onChange } = this.props;
        onChange(event);
    }

    handleKeyDown(event) {
        const { onKeyDown } = this.props;
        if (onKeyDown) {
            onKeyDown(event);
        }
    }

    handleKeyUp(event) {
        const { onKeyUp } = this.props;
        if (onKeyUp) {
            onKeyUp(event);
        }
    }

    handleFocus(e) {
        const { onFocus, bottomBorderOnFocus } = this.props;
        this.inputRef.current.style.border = `solid 1px ${bottomBorderOnFocus}`;
        this.inputRef.current.focus();
        if (onFocus) {
            onFocus(e);
        }
    }

    handleBlur(event) {
        const { onBlur, borderColor } = this.props;
        this.inputRef.current.style.border = `solid 1px ${borderColor}`;

        if (onBlur) {
            onBlur(event);
        }
    }

    render() {
        const {
            labelText,
            labelPosition,
            type,
            value,
            name,
            placeholder,
            error,
            className,
            leftIcon,
            rightIcon,
            disabled,
            dataTest,
        } = this.props;
        let containerClass = `${s.container} ${className}`;
        let labelClass = `${s['input-label']}`;
        let inputClass = `${s.input}`;
        if (leftIcon) {
            inputClass = `${inputClass} ${s['with-left-icon']}`;
        }
        if (rightIcon) {
            inputClass = `${inputClass} ${s['with-right-icon']}`;
        }

        if (labelPosition === 'top') {
            labelClass = `${s['input-label-top']}`;
            containerClass = `${s['container-label-top']} ${className}`;
        }
        if (disabled) {
            containerClass = `${containerClass} ${s.disabled}`;
        }

        return (
            <div className={containerClass}>
                <div className={s.input_header}>
                    {labelText && (
                        <label ref={this.labelRef} className={labelClass} htmlFor={name}>
                            {labelText}
                        </label>
                    )}
                </div>
                <Tooltip
                    content={error}
                    position="bottom-start"
                    theme="error"
                    trigger="none"
                    show={error !== ''}
                >
                    <div className={s['input-container']}>
                        {leftIcon && <Icon icon={leftIcon} className="left-icon" />}

                        <input
                            ref={this.inputRef}
                            className={inputClass}
                            autoComplete="off"
                            type={type}
                            value={value}
                            name={name}
                            id={name}
                            placeholder={placeholder}
                            onChange={this.handleChange}
                            disabled={disabled}
                            onBlur={this.handleBlur}
                            error={error}
                            onKeyDown={this.handleKeyDown}
                            onKeyUp={this.handleKeyUp}
                            onFocus={this.handleFocus}
                            step={type === 'number' ? 'any' : undefined}
                            data-test={dataTest}
                        />
                        {rightIcon && <Icon icon={rightIcon} className="right-icon" />}
                    </div>
                </Tooltip>
            </div>
        );
    }
}

Input.propTypes = {
    labelText: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    borderColor: PropTypes.string,
    paddingRight: PropTypes.number,
    error: PropTypes.string,
    className: PropTypes.string,
    leftIcon: PropTypes.string,
    rightIcon: PropTypes.string,
    customStyle: PropTypes.shape({}),
    labelStyle: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    setFocusOnSpinClick: PropTypes.func,
    dataTest: PropTypes.string,
    bottomBorderOnFocus: PropTypes.string,
};

Input.defaultProps = {
    onKeyDown: undefined,
    onKeyUp: undefined,
    labelText: '',
    value: '',
    type: 'text',
    placeholder: '',
    disabled: false,
    width: 100,
    height: 30,
    borderColor: variables.borderInputLight,
    paddingRight: 0,
    onBlur: undefined,
    onFocus: undefined,
    error: '',
    className: '',
    leftIcon: undefined,
    rightIcon: undefined,
    customStyle: {},
    labelStyle: {},
    labelPosition: '',
    setFocusOnSpinClick: undefined,
    dataTest: undefined,
    bottomBorderOnFocus: variables.primary,
};

export default Input;
