import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import s from './style.css';

class Footer extends PureComponent {
    render() {
        const { text } = this.props;
        return <div className={s.footer}>{text}</div>;
    }
}

Footer.propTypes = {
    text: PropTypes.string.isRequired,
};

export default Footer;
