import I18nJs from 'i18n-js';
import english from './global.en';
import french from './global.fr';
import platform from './global.platform';

const I18n = {
    get(key) {
        return I18nJs.t(key);
    },

    getParameterized(key, replacements) {
        return I18nJs.t(key, replacements);
    },

    pluralize(number, singular, plural) {
        return number > 1 || number === 0 ? plural : singular;
    },

    translate(language) {
        I18nJs.defaultLocale = language;
        I18nJs.locale = language;
    },

    initLanguages() {
        I18nJs.translations = {
            en: english,
            fr: french,
            platform,
        };
    },
};

export default I18n;
