import _ from 'lodash';
import Marionette from 'backbone.marionette';

const WindowContainer = Marionette.Object.extend({
    /**
     * Constructor
     *
     * @param {*} [options] A list of options for the window
     */
    initialize(options) {
        this._isShown = false;
        this._windowOptions = _.extend(
            {
                actions: ['Close'],
                modal: true,
                activate: () => {
                    let resizeReady;
                    try {
                        resizeReady = new Event('resize:content');
                        window.dispatchEvent(resizeReady);
                    } catch (e) {
                        // fallback for IE11
                        resizeReady = document.createEvent('CustomEvent');
                        resizeReady.initCustomEvent('resize:content', true, true, false);
                        document.dispatchEvent(resizeReady);
                    }
                },
                resizable: false,
                scrollable: false,
                visible: false,
                draggable: false,
                pinned: true,
                width: '50%',
                height: '50%',
                overflow: 'visible',
                animation: {
                    open: {
                        effects: 'fadeIn',
                        duration: 500,
                    },
                    close: {
                        effects: 'fadeOut',
                        duration: 300,
                    },
                },
                open: this.trigger.bind(this, 'open'),
                close: this._closeAndFade.bind(this),
            },
            options,
            { visible: false }
        );
        this._window = $('<div />')
            .kendoWindow(this._windowOptions)
            .data('kendoWindow');
    },

    /**
     * Render the window into it's parent view
     */
    show() {
        if (!this._isShown) {
            this._setBodyModalOpenClass();
            this.center();
            this._window.open();
            this._isShown = true;
            _.defer(this.center.bind(this)); // @FIXME Webpack - kendo is messing with window positioning so we force re-centering
        }
    },

    _closeAndFade(e) {
        // userTriggered: true if ESC or kendo X is clicked
        if (e.userTriggered) {
            this.trigger('cancel');
        } else {
            _.defer(this.trigger, 'close');
        }
        this._isShown = false;
        this._removeBodyModalOpenClass();
    },

    close() {
        this._window.close();
    },

    /**
     * Hide the window
     */
    hide() {
        if (this._isShown) {
            this._window.close();
            this._isShown = false;
            this._removeBodyModalOpenClass();
        }
    },

    /**
     * Destroy the window, and make this window completely unusable. A new
     * instance is required.
     */
    destroy() {
        this._window.destroy();
        this._window = null;
        this._windowOptions = null;
        this._removeBodyModalOpenClass();
    },

    /**
     * Center the window
     */
    center() {
        this._window.center();
    },

    /**
     * Set the Window Content
     *
     * @param content
     */
    setContent(content) {
        this._window.content(content);
    },

    resetContent() {
        this._window.content(null);
    },

    /**
     * Set Body Modal-Open class to prevent scrolling of overlay mask
     */
    _setBodyModalOpenClass() {
        $('body').addClass('modal-open');
    },
    /**
     * Remove Body Modal-Open class to re-enable scrolling when overlay is removed
     */
    _removeBodyModalOpenClass() {
        $('body').removeClass('modal-open');
    },
});

export default WindowContainer;
