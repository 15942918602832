import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TooltipTrigger from 'react-popper-tooltip';
import rpts from 'react-popper-tooltip/dist/styles.css';

import s from './tooltip.css';
import { unitSize } from '../../../utils/style.utils';

class Tooltip extends Component {
    getContainerClass() {
        const { className, theme } = this.props;
        const themes = ['dark', 'error', 'warning', 'success', 'info', 'disabled'];
        let containerClass = `${s.tooltipContainer} ${rpts['tooltip-container']}`;

        if (className) {
            containerClass = `${containerClass} ${className}`;
        }

        if (theme && themes.indexOf(theme) >= 0) {
            containerClass = `${containerClass} ${s[theme]}`;
        }

        return containerClass;
    }

    render() {
        const { tooltipPositionTop, position, trigger, content, children, show } = this.props;
        const containerClassname = this.getContainerClass();
        return (
            <TooltipTrigger
                tooltipShown={show}
                placement={position}
                trigger={trigger}
                tooltip={({ getTooltipProps, getArrowProps, tooltipRef, arrowRef, placement }) => (
                    <div
                        {...getTooltipProps({
                            ref: tooltipRef,
                            style: {
                                marginTop: unitSize(tooltipPositionTop),
                            },
                            className: containerClassname,
                            /* your props here */
                        })}
                    >
                        <div
                            {...getArrowProps({
                                ref: arrowRef,
                                'data-placement': placement,
                                className: `${s.tooltipArrow} ${rpts['tooltip-arrow']}`,
                                /* your props here */
                            })}
                        />
                        {content}
                    </div>
                )}
            >
                {({ getTriggerProps, triggerRef }) => (
                    <span
                        {...getTriggerProps({
                            ref: triggerRef,
                            className: rpts.trigger,
                            /* your props here */
                        })}
                    >
                        {children}
                    </span>
                )}
            </TooltipTrigger>
        );
    }
}

Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    position: PropTypes.string,
    trigger: PropTypes.string,
    className: PropTypes.string,
    theme: PropTypes.string,
    show: PropTypes.bool,
    tooltipPositionTop: PropTypes.number,
};

Tooltip.defaultProps = {
    position: 'auto',
    trigger: 'hover',
    className: undefined,
    theme: undefined,
    show: undefined,
    tooltipPositionTop: 0,
};

export default Tooltip;
