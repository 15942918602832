export const selectInventoryItems = (previousSelection, selectedIds) => {
    const newSelection = { ...previousSelection };
    selectedIds.forEach(id => {
        newSelection[id] = true;
    });
    return newSelection;
};

export const unselectInventoryItems = (previousSelection, unselectedIds) => {
    const newSelection = { ...previousSelection };
    unselectedIds.forEach(id => {
        delete newSelection[id];
    });
    return newSelection;
};

export const selectPrice = ({ custom_price: customPrice, suggested_price: suggestedPrice }) => {
    const parsedCustomPrice = Number.parseFloat(customPrice);
    const parsedSuggestedPrice = Number.parseFloat(suggestedPrice);

    if (!Number.isNaN(parsedCustomPrice) && parsedCustomPrice > 0) {
        return parsedCustomPrice;
    }
    return Number.isNaN(parsedSuggestedPrice) ? suggestedPrice : parsedSuggestedPrice;
};
