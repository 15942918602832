export default {
    LblYourCurrency: 'Your currency',
    BtnCancel: 'Cancel',
    BtnConfirm: 'Confirm',
    TooltipEdit: 'Edit',
    TooltipDelete: 'Delete',
    BtnChoose: 'Choose screens',
    BtnChange: 'Click to change',
    BtnCopy: 'Copy',
    TooltipChoose: 'Click to choose associated screens',
    TooltipChange: 'Click to change associated screens',
    TooltipFacesChoose: 'Click to choose associated faces',
    TooltipFacesChange: 'Click to change associated faces',
    BtnSave: 'Save',
    LblPreview: 'Preview:',
    LblErrorCurrencyEmpty: 'Currency cannot be empty!',
    NavMenuUserManagement: 'User Management',
    NavMenuRatecardManagement: 'Digital Rate Card Management',
    NavMenuStaticRatecardManagement: 'Static Rate Card Management',
    NavMenuGeneral: 'General',
    NavMenuSyncSettings: 'Sync Settings',
    NavMenuImportExport: 'Import & Export',
    NavMenuDynamicPricing: 'Variable Pricing',
    NavMenuDigital: 'Digital Inventory',
    NavMenuStatic: 'Static Inventory',
    NavMenuIntegration: 'Integration',
    SearchPlaceholder: 'What are you looking for?',
    INVALID_ROLE_ID: 'Role assignation impossible',
    INVALID_NAME: 'Invalid Name',
    PASSWORD_TOO_SHORT: 'Invalid Password',
    PASSWORD_MISSING: 'Password is required',
    INVALID_EMAIL: 'Invalid Email',
    INVALID_LAT_LONG: 'Format of coordinates is invalid. Use Google formatting best practices.',
    MISSING_VALUE: 'No value found for this line.',
    MISSING_HEADING: 'No column header found for this line.',
    INVALID_HEADING: 'Column header not recognized. Try re-exporting CSV file for valid template.',
    EMAIL_DUPLICATE: 'Email already assign to another user',
    CANNOT_DISABLE_YOURSELF: 'You cannot deactivate your account',
    TOAST_SERVER_ERROR: 'An unknown error occurred. Please try again',
    LblSetPassword: 'Click to set',
    LblChangePassword: 'Click to change',
    LblForbiddenTitle: "You don't have access to this page",
    LblForbiddenMsg:
        'If you believe you should have access, ask your Sales Tool administrator to grant you access.',
    BtnRedirecDashboard: 'Take me to the Dashboard',
    TooltipCancel: 'Cancel',
    TooltipApply: 'Apply',
    EmailRequiredError: 'Email is required',
    EmailInvalidError: 'Please enter a valid email address',
    NameRequiredError: 'Name required',
    DatePlaceholder: 'Select date',
    DateRequiredError: {
        start_date: 'Start date required',
        end_date: 'End date required',
    },
    SalesforceEmailValidationError: 'Valid email address is required',
    URLInvalidError: 'Valid url is required',
    InvalidTokenLength: 'Token has to be less than 255 characters long',
    FlightDurationRequiredError: 'Flight Duration is required',
    SlotDurationRequiredError: 'Slot Duration is required',
    PriceRequiredError: 'Price is required',
    MultiplierRequiredError:
        'Value must be greater than &#34;-100%&#34;. Can be any positive integer.',
    LblAudienceData: 'Audience data',
    LblStaticInventoryData: 'Static Inventory',
    LblDigitalInventoryData: 'Digital Inventory',
    LblCategoryExclusionsData: 'Category Exclusion',
    LblRevenue: 'Revenue',
    LblScreenList: 'Inventory',
    LblPointsOfInterest: 'Points of interest',
    LblAudienceDescription:
        'Start by exporting to get a list of your screens and a formatting guide to start from. Then import the updated file with your audience data.',
    LblStaticInventoryDescription:
        'Here you can import a list of your Static Inventory to synchronise Broadsign Direct with your system.',
    LblDigitalInventoryDescription:
        'Here you can import and export a list of your Digital Inventory for use in your existing systems. You can also reimport it if you need to add reference IDs for mapping purposes.',
    LblCategoryExclusionsDescription: 'Here you can import a list of exclusion.',
    LblRevenueDescription:
        'Start by choosing the targeted date range.\n Then export all the campaigns and their associated clients.',
    LblScreenListDescription:
        'Here you can export a list of your Broadsign Direct inventory for use in your existing systems.',
    LblPOIDescription:
        'Start by exporting to get a formatting guide to start from. Then import your updated file with your point of interest data.',
    BtnExportCSV: 'Export',
    BtnImportCSV: 'Import',
    LblLastExportOn: 'Last export :',
    LblLastImportOn: 'Last import :',
    CountOfPOIAvailable: '{{itemCount}} point of interest imported',
    'CountOfPOIAvailable-plural': '{{itemCount}} points of interest imported',
    ErrorCouldNotImportCSV: 'Could not import the CSV file',
    ErrorFileSizeTooLarge: 'The selected file is too large',
    ErrorCouldNotExportAudienceCSV: 'Could not generate the audience CSV file',
    ErrorCouldNotExportUserCSV: 'Could not generate the user CSV file',
    ErrorCouldNotExportRevenueCSV: 'Could not generate the revenue CSV file',
    ErrorCouldNotExportInventoryListCSV: 'Could not generate the {{type}} inventory CSV file',
    ErrorCouldNotExportPOI: 'Could not generate the POI CSV file',
    ErrorCouldNotExportCategoryExclusionCSV: 'Could not generate the category exclusion CSV file',
    AssociatedScreens: 'Associated screens',
    AssociatedFaces: 'Associated faces',
    ToastMessageRatecardScreensSaved: 'Rate card screen selection was successfully saved!',
    ToastMessageRatecardFacesSaved: 'Rate card face selection was successfully saved!',
    UNKNOWN_ERROR: 'Resource update impossible',
    LblAudienceIntegration: 'Audience integration',
    LblAudienceIntegrationDescription:
        'Use this URL to synchronize audience data with Broadsign Control.',
    LblContractId: 'Contract ID',
    LblDigitalDefaultParameters: 'Default Parameters',
    LblExternalSaturation: 'Saturation control',
    LblExternalSaturationDescription:
        'Use this URL to control the external saturation in Broadsign Control.',
    LblTypesOfBuyToRebalance: 'Rebalancing - Types of Buys',
    LblTypesOfBuyToRebalanceDescription:
        'Select which Types of Buys you wish to use the Rebalancing feature for.',
    LblTypesOfBuyToRebalanceMustBeEnabledDescription:
        'Rebalancing is disabled on this domain - contact services to enable it. Once enabled, these settings will automatically be applied.',
    LblCompanyInformation: 'Company information',
    LblProposalOptions: 'Proposal options',
    LblProposalHoldDuration: 'Proposal hold automatic expiry time',
    HeadingSchedulingParameters: 'Scheduling parameters',
    HintDigitalParametersText: 'Choose the default parameters for your digital network.',
    HintSchedulingParametersText:
        'Define your standard flight information for your static inventory',
    LblFirstDayOfFlight: 'First day of flight',
    LblLeadTime: 'Lead time',
    LblLocalesSettings: 'Locales settings',
    LblProposalHeader: 'Proposal header',
    LblLetterheadExplanation: 'The chosen image will appear at the top of every generated proposal',
    LblLetterheadSuggestedResolution: 'Suggested size: 2480x400',
    DOMAIN_NOT_FOUND: 'Domain not found',
    DOMAIN_HAS_NO_LETTERHEAD: 'No letterhead has been uploaded for this domain',
    LblProposalThemeColor: 'Proposal accent color',
    LblProposalThemeColorUpdateFail:
        'Theme color could not be saved.  Please click save to try again.',
    LblShareOfVoice: 'Default share of voice',
    LblSlotDuration: 'Default slot duration',
    LblStaticFlightDuration: 'Flight duration',
    LblStaticOverrideCheckbox: 'Allow users to override scheduling parameters',
    LblCloseOfPlanPreview: 'Export Preview',
    LblCloseOfPlanClose: 'Close Plan',
    ErrorCouldNotExportCloseOfPlanPreview: 'Could not generate the Preview.',
    ErrorCouldNotExportCloseOfPlanClose: 'Could not generate the Close of Plan.',
    HeaderCloseOfPlanSection: 'Close of plan',
    DescriptionCloseOfPlanSection:
        'Choose the date of the plan you wish to close and download the booking state of all faces.',
    LblImportPlan: 'Import plan',
    LblImportPlanDescription: 'Update the state of your static inventory',
    LblLine: 'Line',
    EMPTY_FILE: 'The file provided is empty',
    REF_SCREEN_NOT_FOUND:
        'Screen "{{name}}" (ID:{{id}}) not found in your inventory and will be ignored during import',
    REF_ID_AFFECTED: 'Reference ID will be changed for Screen "{{name}}" (ID:{{id}}).',
    SCREEN_ID_MISSING: 'ID is missing for Screen {{name}}',
    REF_DUPLICATE_VALUE: 'Reference ID {{reference_id}} is duplicate',
    SCREEN_DUPLICATE_VALUE:
        'Screen {{name}} ID:{{id}} is duplicate and will be ignored during import',
    INVALID_ID_VALUE: 'Invalid ID value',
    ValidationWarnings: 'Validation reported some warnings',
    ValidationErrors: 'Validation reported some errors',
    ReferenceIDAffected: '{{count}} reference IDs on digital inventory will be updated',
    DigitalInventoryUpdated: 'Your digital inventory can be updated',
    LblCategoryExclusionsSuccessMessage: 'Category exclusions file has been successfully imported.',
    managementTitle: {
        automaticVariablePricing: 'Variable Pricing',
    },
    addButtonsLabel: {
        default: 'Add',
        newPricing: 'New Pricing',
        newRatecard: 'New Rate Card',
        newUser: 'Add New User',
    },
    RoleSales: 'Sales',
    RoleAdmin: 'Admin',
    RoleManager: 'Manager',
    'RoleSales Operator': 'Sales Coordinator',
    RoleServices: 'Services',
    'RoleSales Coordinator': 'Sales Coordinator',
    EmailLabel: 'Email',
    PasswordLabel: 'Password',
    SalesforceInstanceLabel: 'Salesforce Instance',
    TokenLabel: 'Token',
    SalesforceIntegrationHeading: 'Salesforce Integration',
    PriorityLevels: {
        Heading: 'Rebalancing priority level',
        Subtitle: 'Choose rebalancing priority level.',
        HeaderLevel: 'Level',
        HeaderName: 'Name',
        AddButton: 'Add Priority',
        ErrorDuplicate: 'This name is already taken. Please choose a new one.',
        ErrorLength: 'Please choose a name with a maximum length of 8 characters.',
        ErrorDeleteUsedPriority:
            'This priority level links to a proposal line item, so you are unable to remove it.',
        loadingError: 'Unable to load your priority levels. Please try again later.',
    },
};
