import { MATCHING_COUNT_UNITS } from '../utils/inventory.utils';

const createInitialState = () => {
    const currentDateTime = new Date();
    return {
        global: {
            activePage: '',
            authentication: {
                loading: true,
                success: false,
            },
            user: {},
            domain: {},
            locale: 'en',
            errors: [],
            thirdPartyCPMSettings: {},
        },
        inventory: {
            flightDuration: {
                start_date: '1970-01-01',
                end_date: '1970-01-01',
                start_time: '00:00:00',
                end_time: '23:59:59',
                dow_mask: 127,
                multiplier: 14,
                flight_duration: 1,
                flight_type: 0,
            },
            sharedProposalGoalToggle: false,
            sharedProposalGoalId: null,
            slotDuration: 15,
            inventoryItems: {},
            gridRows: [],
            selectedInventoryItemIds: {},
            filters: [],
            cart: {},
            proposalItem: {},
            lightbox: {},
            cartError: false,
            summary: {
                groupCount: undefined,
                screenCount: undefined,
                cpm: undefined,
                impressions: undefined,
                budget: undefined,
                shareOfVoice: undefined,
            },
            inventoryType: 'digital',
            mode: [],
            typeOfBuyErrors: {},
            sortBy: [{ field: 'name', dir: 'asc' }],
            deployedOnly: false,
            availableOnly: false,
            categoryIds: [],
            matchingCountUnit: MATCHING_COUNT_UNITS.SCREENS,
            defaultMode: {},
            matchingTotals: {},
            unfilteredCounts: {},
            thirdPartyCpm: {
                loading: false,
                cancelTokenSource: undefined,
                cpm: undefined,
                cpm_override_type: 'cpm',
                cpm_value: undefined,
                error: {},
            },
        },
        proposalBuilder: {
            fields: {},
            proposal: {},
            lineItems: {},
            goals: {},
            summary: {},
            proposalItemsInAction: {},
            overlaps: [],
            creativeManagement: {},
            jwtToken: {
                expiry: new Date().setHours(currentDateTime.getHours() - 1), // initialize to expired
                value: '',
            },
        },
        notification: {
            message: '',
            variant: 'warning',
        },
    };
};

export default createInitialState;
