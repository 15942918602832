import { AVAILABILITY_STATUS } from '../../../../utils/static/constants/availability';

const applyFilter = (faces, filters) => {
    const availabilityFilters = filters.filter(filter => filter.type === 'availabilityStatus');
    if (availabilityFilters.length === 0 || availabilityFilters === AVAILABILITY_STATUS.length)
        return faces;

    return faces.filter(face =>
        availabilityFilters.some(filter => filter.id === face.availability)
    );
};

export default applyFilter;
