import { combineReducers } from 'redux';
import globalReducer from './globalReducer';
import inventoryReducer from './inventory/inventoryReducer';
import staticInventoryReducer from './inventory/static/staticInventoryReducer';
import proposalBuilderReducer from './proposalBuilder/proposalBuilderReducer';
import notificationReducer from './notification/notificationReducer';

const rootReducer = combineReducers({
    global: globalReducer,
    inventory: inventoryReducer,
    staticInventory: staticInventoryReducer,
    proposalBuilder: proposalBuilderReducer,
    notification: notificationReducer,
});

export default rootReducer;
