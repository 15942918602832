export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
};

// Salesforce integration validation
export const validateSalesforcePassword = password => password.length;

export const validateSalesforceToken = token => !token || token.length < 255;

export const validateSalesforceInstance = instance => {
    const re = /^https?:\/\/.+/;
    return re.test(instance.toLowerCase());
};
