import React, { PureComponent } from 'react';

import LoginView from './container/LoginView';
import Events, { getPageTitle } from '../../utils/Events';

class App extends PureComponent {
    constructor(props) {
        super(props);
        Events.loadPage(getPageTitle(window));
    }

    render() {
        return <LoginView />;
    }
}

export default App;
