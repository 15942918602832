import createInitialState from '../initialState';
import {
    SET_PROPOSAL_BUILDER_FIELDS,
    SET_PROPOSAL_DATA,
    SET_LINE_ITEM_DATA,
    SET_LINE_ITEMS,
    SET_PROPOSAL_SUMMARY_DATA,
    ADD_PROPOSAL_ITEM_INLINE_ACTION,
    REMOVE_PROPOSAL_ITEM_INLINE_ACTION,
    SET_PROPOSAL_GOALS,
    REMOVE_PROPOSAL_GOAL_ACTION,
    SET_PROPOSAL_GOAL_INLINE_ACTION,
    SET_OVERLAPS,
    CLEAR_OVERLAPS,
    SET_CREATIVE_MANAGEMENT_STATUS,
    GET_CREATIVE_MANAGEMENT_STATUS_BEGIN,
    GET_CREATIVE_MANAGEMENT_STATUS_END,
    SET_CREATIVE_MANAGEMENT_STATUS_ERROR,
    SET_CREATIVE_MANAGEMENT_STATUS_PERMISSIONS_ERROR,
    SET_JWT,
} from '../../actions/actionTypes';

const proposalBuilderReducer = (state = createInitialState().proposalBuilder, action) => {
    const newState = { ...state };
    switch (action.type) {
        case SET_PROPOSAL_BUILDER_FIELDS: {
            const whitelistedFields = {
                name: true,
                client_id: true,
                client_name: true,
                contract_number: true,
                contact_name: true,
                contact_phone: true,
                contact_email: true,
                client_address: true,
                category_ids: true,
            };
            Object.keys(whitelistedFields).forEach(f => {
                newState.fields[f] = action.payload[f];
            });
            break;
        }
        case SET_PROPOSAL_DATA:
            newState.proposal = { ...newState.proposal, ...action.payload };
            break;
        case SET_LINE_ITEM_DATA: {
            const { id: lineItemId } = action.payload;
            newState.lineItems = { ...newState.lineItems, [lineItemId]: { ...action.payload } };
            break;
        }
        case SET_LINE_ITEMS: {
            newState.lineItems = { ...action.payload };
            break;
        }
        case SET_PROPOSAL_GOALS: {
            newState.goals = action.payload;
            break;
        }
        case SET_PROPOSAL_GOAL_INLINE_ACTION: {
            const { id: goalId } = action.payload;
            newState.goals = { ...newState.goals, [goalId]: { ...action.payload } };
            break;
        }
        case SET_PROPOSAL_SUMMARY_DATA: {
            newState.summary = { ...action.payload };
            break;
        }
        case ADD_PROPOSAL_ITEM_INLINE_ACTION: {
            const { ids: proposalItemIds } = action.payload;
            const newItems = proposalItemIds.reduce((aggr, id) => ({ ...aggr, [id]: true }), {});
            newState.proposalItemsInAction = {
                ...newState.proposalItemsInAction,
                ...newItems,
            };
            break;
        }
        case REMOVE_PROPOSAL_ITEM_INLINE_ACTION: {
            const { ids: proposalItemIds } = action.payload;

            newState.proposalItemsInAction = Object.keys(newState.proposalItemsInAction).reduce(
                (aggr, id) => {
                    if (!proposalItemIds.includes(parseInt(id, 10))) {
                        // eslint-disable-next-line no-param-reassign
                        aggr[id] = true;
                    }
                    return aggr;
                },
                {}
            );
            break;
        }
        case REMOVE_PROPOSAL_GOAL_ACTION: {
            const { goals } = state;
            newState.goals = Object.keys(goals).reduce((acc, goalId) => {
                if (goals[goalId].id !== action.payload) {
                    acc[goalId] = goals[goalId];
                }

                return acc;
            }, {});
            break;
        }
        case SET_CREATIVE_MANAGEMENT_STATUS:
            newState.creativeManagement = { ...newState.creativeManagement, ...action.payload };
            break;
        case SET_CREATIVE_MANAGEMENT_STATUS_ERROR:
            newState.creativeManagement = { ...newState.creativeManagement, error: true };
            break;
        case SET_CREATIVE_MANAGEMENT_STATUS_PERMISSIONS_ERROR:
            newState.creativeManagement = {
                ...newState.creativeManagement,
                permissionError: true,
            };
            break;
        case SET_JWT: {
            const secondsToAdd = action.payload.expiresIn
                ? parseInt(action.payload.expiresIn, 10)
                : 300; // set a default expiry of 300 seconds ~ 5 min
            newState.jwtToken = {
                value: action.payload.value,
                expiry: new Date(new Date().getTime() + secondsToAdd * 1000),
            };
            break;
        }
        case GET_CREATIVE_MANAGEMENT_STATUS_BEGIN:
            newState.creativeManagement = {
                ...newState.creativeManagement,
                loading: true,
                error: null,
            };
            break;
        case GET_CREATIVE_MANAGEMENT_STATUS_END:
            newState.creativeManagement = { ...newState.creativeManagement, loading: false };
            break;
        case SET_OVERLAPS: {
            newState.overlaps = action.payload;
            break;
        }
        case CLEAR_OVERLAPS: {
            newState.overlaps = [];
            break;
        }
        default:
            break;
    }
    return newState;
};

export default proposalBuilderReducer;
